import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { useDeleteRetterRelevo } from '../../services/retter-relevos';
import { RetterRelevosDeleteDisabledModal } from './modals/RetterRelevosDeleteDisabledModal';

interface DeleteRetterRelevoProps {
  retterRelevoId: string;
  canDeleteOffer: boolean;
}

export const DeleteRetterRelevo = ({ retterRelevoId, canDeleteOffer }: DeleteRetterRelevoProps) => {
  const translations = useTranslations();

  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const {
    isOpen: isModalDeleteDisabledClose,
    onClose: onModalDeleteDisabledClose,
    onOpen: onModalDeleteDisabledOpen,
  } = useDisclosure();
  const { mutate: deleteRetterRelevo, isLoading: isDeletingRetterRelevo } = useDeleteRetterRelevo();
  const handleDeleteClick = async () => {
    if (canDeleteOffer) {
      onDeleteModalOpen();
    } else {
      onModalDeleteDisabledOpen();
    }
  };
  const onSubmit = () => {
    deleteRetterRelevo(
      { retterRelevoId },
      {
        onSuccess: () => {
          onDeleteModalClose();
        },
      },
    );
  };

  return (
    <>
      <Box opacity={canDeleteOffer ? 1 : 0.4}>
        <IconButton size="sm" aria-label="Close" icon={<CloseIcon />} onClick={handleDeleteClick} />
      </Box>
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>🛑 {translations('retter_relevo_delete_modal_title')}</ModalHeader>
          <ModalCloseButton />
          <Flex h="100%" display="column" p={4}>
            <Text mb={2}>{translations('retter_relevo_delete_modal_body')}</Text>
            <Button width={'100%'} variant="redSolid" mb={2} onClick={onSubmit} isDisabled={isDeletingRetterRelevo}>
              {translations('retter_relevo_modal_delete_submit_button')}
            </Button>
            <Button width={'100%'} mb={2} onClick={onDeleteModalClose}>
              {translations('retter_relevo_modal_close_button')}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      <RetterRelevosDeleteDisabledModal isOpen={isModalDeleteDisabledClose} onClose={onModalDeleteDisabledClose} />
    </>
  );
};
