import React from 'react';

import { ReturnCodesContextProvider } from './context/ReturnCodesContext';
import { ReturnCodes } from './ReturnCodes';

export const ReturnCodesWrapper = () => {
  return (
    <ReturnCodesContextProvider>
      <ReturnCodes></ReturnCodes>
    </ReturnCodesContextProvider>
  );
};
