import React from 'react';
import { components } from 'react-select';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../../services/restaurants';

export const LocationValueContainer = ({ children, ...props }: any) => {
  const translations = useTranslations();
  const { restaurantIds } = useRestaurantsData();
  const { getValue, hasValue } = props;
  const nbValues = getValue().length;
  const tmpChildren = [...children];
  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  if (restaurantIds && restaurantIds.length === nbValues) {
    tmpChildren[0] = translations('all_locations', {
      '{{count}}': nbValues,
    });
    return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
  }
  tmpChildren[0] = translations('selected_locations', {
    '{{count}}': nbValues,
  });
  return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
};
