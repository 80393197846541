import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsWithReturnCodesCount, useReturnCodesForRestaurant } from '../../services/return-codes';
import { ReturnCodesManageModal } from './components/ReturnCodesManage/ReturnCodesManageModal';
import { ReturnCodesMediumTable } from './components/ReturnCodesTable/ReturnCodesMediumTable';
import { ReturnCodesMobileTable } from './components/ReturnCodesTable/ReturnCodesMobileTable';
import { ReturnCodesTable } from './components/ReturnCodesTable/ReturnCodesTable';
import { ReturnCodesTableHeading } from './components/ReturnCodesTableHeading/ReturnCodesTableHeading';
import { ReturnCodesTableMediumHeading } from './components/ReturnCodesTableHeading/ReturnCodesTableMediumHeading';
import { ReturnCodesTableMobileHeading } from './components/ReturnCodesTableHeading/ReturnCodesTableMobileHeading';
import { useReturnCodesContext } from './context/hooks/useReturnCodesContext';
import { RETURN_CODES_DEFAULT_TABLE_LIMIT } from './ReturnCodes.consts';

export const ReturnCodes = () => {
  const translations = useTranslations();

  const { returnCodesState } = useReturnCodesContext();

  const {
    data: locationsData,
    isFetching: isLocationsDataFetching,
    refetch: refetchLocations,
  } = useRestaurantsWithReturnCodesCount(
    {
      searchLocationText: returnCodesState.searchLocation,
      cityIds: returnCodesState.selectedCities,
      locationWithIssueType: returnCodesState.selectedLocationWithIssueType,
      returnCodeAge: returnCodesState.selectedReturnCodeAgeType,
    },
    returnCodesState.page - 1,
    RETURN_CODES_DEFAULT_TABLE_LIMIT,
  );

  const {
    data: returnCodesData,
    isFetching: isReturnCodesDataFetching,
    refetch: refetchReturnCodes,
  } = useReturnCodesForRestaurant(returnCodesState.locationIdToManage as string);

  return (
    <MainPageContent>
      <MainPageHeading>{translations('return_codes_heading')}</MainPageHeading>
      <ReturnCodesTableHeading
        display={['none', 'none', 'none', 'none', 'block']}
        refetchLocations={refetchLocations}
        refetchReturnCodes={refetchReturnCodes}
      ></ReturnCodesTableHeading>
      <ReturnCodesTableMediumHeading
        display={['none', 'none', 'block', 'block', 'none']}
        refetchLocations={refetchLocations}
        refetchReturnCodes={refetchReturnCodes}
      ></ReturnCodesTableMediumHeading>
      <ReturnCodesTableMobileHeading
        display={['block', 'block', 'none', 'none', 'none']}
        refetchLocations={refetchLocations}
        refetchReturnCodes={refetchReturnCodes}
      ></ReturnCodesTableMobileHeading>
      <Flex width="100%">
        <Box display={['none', 'none', 'none', 'none', 'block']}>
          <ReturnCodesTable data={locationsData} isFetching={isLocationsDataFetching}></ReturnCodesTable>
        </Box>
        <Box display={['none', 'none', 'block', 'block', 'none']}>
          <ReturnCodesMediumTable data={locationsData} isFetching={isLocationsDataFetching}></ReturnCodesMediumTable>
        </Box>
        <Box display={['block', 'block', 'none', 'none', 'none']}>
          <ReturnCodesMobileTable data={locationsData} isFetching={isLocationsDataFetching}></ReturnCodesMobileTable>
        </Box>
      </Flex>

      <ReturnCodesManageModal
        refetchLocations={refetchLocations}
        isReturnCodesDataFetching={isReturnCodesDataFetching}
        refetchReturnCodes={refetchReturnCodes}
        returnCodesData={returnCodesData}
      ></ReturnCodesManageModal>
    </MainPageContent>
  );
};
