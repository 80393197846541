import { useMemo } from 'react';

import { useInventoryCountSnapshot } from '../../../../../../../../services/inventory-counts';
import { useInventoryCountsContext } from '../../../../../../context/hooks/use-inventory-counts-context.hook';

export const useReview = () => {
  const { currentInventoryCountId } = useInventoryCountsContext();
  const {
    data: inventoryCount,
    isLoading: isLoadingInventoryCount,
    isFetching: isFetchingInventoryCount,
  } = useInventoryCountSnapshot(currentInventoryCountId || '');

  const inventoryTotalItems = useMemo(() => {
    if (!inventoryCount) {
      return 0;
    }
    return inventoryCount.currentInventoryScannedItems
      ?.map((inventory) => inventory.currentInventory)
      .reduce((acc, currentValue) => acc + currentValue, 0);
  }, [inventoryCount]);

  const inventoryScannedItems = useMemo(() => {
    if (!inventoryCount) {
      return 0;
    }
    return inventoryCount.currentInventoryScannedItems
      ?.map((inventory) => inventory.scannedItems)
      .reduce((acc, currentValue) => acc + currentValue, 0);
  }, [inventoryCount]);

  const inventoryMissingItems = useMemo(() => {
    if (inventoryScannedItems !== undefined && inventoryTotalItems !== undefined) {
      return inventoryTotalItems - inventoryScannedItems;
    }
  }, [inventoryScannedItems, inventoryTotalItems]);

  return {
    inventoryCount,
    inventoryScannedItems,
    inventoryMissingItems,
    inventoryTotalItems,
    additionalInformation: inventoryCount?.additionalInformation || null,
    isLoading: isLoadingInventoryCount || isFetchingInventoryCount,
  };
};
