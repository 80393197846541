import React from 'react';

import HowItWorksModal from '../../../../common/components/HowItWorks/HowItWorksModal';
import { useHtmlTranslations, useTranslations } from '../../../../contexts/LocalizationContext';

export const ReturnCodesHowItWorksModal = () => {
  const translations = useTranslations();
  const htmlTranslations = useHtmlTranslations();
  const sections = [
    {
      title: translations('return_codes_how_it_works.creating_new_code_header'),
      content: [
        htmlTranslations('return_codes_how_it_works.creating_new_code_content_point_1'),
        htmlTranslations('return_codes_how_it_works.creating_new_code_content_point_2'),
        htmlTranslations('return_codes_how_it_works.creating_new_code_content_point_3'),
        htmlTranslations('return_codes_how_it_works.creating_new_code_content_point_4'),
      ],
    },
    {
      title: translations('return_codes_how_it_works.deactivating_an_old_code_header'),
      content: [
        htmlTranslations('return_codes_how_it_works.deactivating_an_old_code_content_point_1'),
        htmlTranslations('return_codes_how_it_works.deactivating_an_old_code_content_point_2'),
        htmlTranslations('return_codes_how_it_works.deactivating_an_old_code_content_point_3'),
      ],
    },
    {
      title: translations('return_codes_how_it_works.managing_multiple_codes_header'),
      content: [
        htmlTranslations('return_codes_how_it_works.managing_multiple_codes_content_point_1'),
        htmlTranslations('return_codes_how_it_works.managing_multiple_codes_content_point_2'),
        htmlTranslations('return_codes_how_it_works.managing_multiple_codes_content_point_3'),
      ],
    },
    {
      title: translations('return_codes_how_it_works.troubleshooting_scanning_issues_header'),
      content: [
        htmlTranslations('return_codes_how_it_works.troubleshooting_scanning_issues_content_point_1'),
        htmlTranslations('return_codes_how_it_works.troubleshooting_scanning_issues_content_point_2'),
        htmlTranslations('return_codes_how_it_works.troubleshooting_scanning_issues_content_point_3'),
      ],
    },
    {
      title: translations('return_codes_how_it_works.best_practices_header'),
      content: [
        htmlTranslations('return_codes_how_it_works.best_practices_content_point_1'),
        htmlTranslations('return_codes_how_it_works.best_practices_content_point_2'),
        htmlTranslations('return_codes_how_it_works.best_practices_content_point_3'),
      ],
    },
  ];
  return <HowItWorksModal sections={sections} />;
};
