import React, { JSX, useEffect, useMemo } from 'react';
import Select, { MenuPlacement, SingleValue, StylesConfig } from 'react-select';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { RestaurantInfo } from '../../../contexts/SessionContext/SessionContext';
import { SelectOption } from '../Select/model/selectOption';

type RestaurantSelectProps = {
  userRestaurants: RestaurantInfo[];
  restaurantId: string | null;
  setRestaurantId: (restaurantId: string | null) => void;
  resetRestaurantId: () => void;
  styles: StylesConfig<SelectOption>;
  menuPlacement?: MenuPlacement;
};

export const RestaurantSelect = ({
  userRestaurants,
  restaurantId,
  setRestaurantId,
  resetRestaurantId,
  styles,
  menuPlacement,
}: RestaurantSelectProps): JSX.Element | null => {
  const translations = useTranslations();
  const restaurantSelectOptions = useMemo(
    () =>
      userRestaurants?.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [userRestaurants],
  );

  const userRestaurantIds = useMemo(() => userRestaurants.map((r) => r.id), [userRestaurants]);

  useEffect(() => {
    if (!userRestaurantIds.includes(restaurantId || '')) {
      resetRestaurantId();
    }
  }, [resetRestaurantId, restaurantId, userRestaurantIds]);

  const handleRestaurantChange = (option: SingleValue<{ value: string; label: string }>) => {
    if (option) {
      setRestaurantId(option.value);
    }
  };

  if (userRestaurants?.length === 1) return null;

  return (
    <Select<SelectOption>
      aria-label={'restaurant-select'}
      value={restaurantId ? restaurantSelectOptions.find((restaurant) => restaurant.value === restaurantId) : null}
      placeholder={translations('restaurant_select_placeholder')}
      styles={styles}
      options={restaurantSelectOptions}
      onChange={handleRestaurantChange}
      menuPlacement={menuPlacement ?? 'auto'}
    />
  );
};
