import React, { ComponentProps, JSX } from 'react';
import { Message } from 'react-hook-form';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Flex, FormControl, FormLabel, Text, Tooltip } from '@chakra-ui/react';

import colors from '../../../styles/colors';

type FormGroupProps = {
  label: React.ReactNode;
  labelTooltipText?: React.ReactNode;
  inputId: string;
  validationError?: string | Message;
  errorMessageProps?: ComponentProps<typeof Text>;
  isVertical?: boolean;
  reverseLayout?: boolean;
  isBold?: boolean;
  isIndented?: boolean;
  isCentred?: boolean;
  srOnly?: true | 'focusable';
  children: React.ReactElement;
};

export const FormGroup = ({
  label,
  labelTooltipText,
  validationError,
  errorMessageProps,
  inputId,
  isVertical = false,
  reverseLayout = false,
  isBold = false,
  isIndented = false,
  isCentred = false,
  srOnly = undefined,
  children,
}: FormGroupProps): JSX.Element => {
  const clonedInput = React.cloneElement(children, {
    id: inputId,
    ...('name' in children.props && { isInvalid: !!validationError || validationError }),
  });

  const Label = () => (
    <Flex
      alignItems="top"
      srOnly={srOnly}
      width={isCentred ? '100%' : '60%'}
      justifyContent={isCentred ? 'center' : 'start'}
    >
      <FormLabel
        htmlFor={inputId}
        mr={isVertical ? 1 : 2}
        fontWeight={isBold ? 'bold' : 'normal'}
        pl={isIndented ? 6 : 0}
      >
        {label}
      </FormLabel>
      {labelTooltipText && (
        <Tooltip
          placement="top-end"
          bgColor={colors.grey[900]}
          color={colors.white}
          fontSize="12px"
          hasArrow
          label={labelTooltipText}
        >
          <QuestionOutlineIcon color={colors.black} data-testid="tooltip-trigger-icon" />
        </Tooltip>
      )}
    </Flex>
  );

  return (
    <FormControl isInvalid={Boolean(validationError)}>
      <Flex direction={isVertical ? 'column' : 'row'}>
        {!reverseLayout && <Label />}
        <Flex direction="column" pt={0} width="100%">
          {clonedInput}
          <Text
            color={colors.red[500]}
            fontSize="12px"
            textAlign={isCentred ? 'center' : 'left'}
            {...errorMessageProps}
            height={4}
          >
            {validationError || ''}
          </Text>
        </Flex>
        {reverseLayout && <Label />}
      </Flex>
    </FormControl>
  );
};
