import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Heading, Image, Text, useDisclosure } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';

import Dialog from '../../../../../../../common/components/ConfirmationDialog/Dialog';
import { LinkButton } from '../../../../../../../common/components/LinkButton';
import { PageSectionHeading } from '../../../../../../../common/components/PageSectionHeading/PageSectionHeading';
import { TableType } from '../../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../../common/components/Table/Table';
import TableHeaderWithOrder from '../../../../../../../common/components/TableHeaderWithOrder/TableHeaderWithOrder';
import { useTranslations } from '../../../../../../../contexts/LocalizationContext';
import { useInventoryCounts } from '../../../../../../../services/inventory-counts';
import { formatDateTime } from '../../../../../../../utils/dateHelper';
import { useInventoryCountsContext } from '../../../../../context/hooks/use-inventory-counts-context.hook';
import { InventoryCountStatus } from '../../../../../model/enums/inventory-count-status.enum';
import { InventoryCountResponse } from '../../../../../model/inventory-count-response';
import { InventoryCountsFormValues } from '../../../../../model/inventory-counts-form-values';
import { InventoryCountsOrderField } from '../../../../../model/inventory-counts-order-field.enum';
import {
  INVENTORY_COUNT_EMPTY_TABLE_VALUE,
  INVENTORY_COUNTS_DEFAULT_TABLE_LIMIT,
} from '../../../../../utils/inventory-counts.consts';
import {
  getInventoryCountInventory,
  getInventoryCountLostOverviewValue,
  getStatusTranslationKey,
} from '../../../../../utils/inventory-counts.helper';
import { useInventoryCountsHistoryState } from '../../hooks/use-inventory-counts-history-state.hook';

export const getIconPath = (iconName: string) => require(`../../../../../../../assets/icons/${iconName}`);

export const InventoryOverview = () => {
  const translations = useTranslations();
  const confirmContinueCancelRef = useRef(null);
  const continueInventoryCountConfirmModal = useDisclosure();
  const { handlePageChange, inventoryCountsState, handleOrderInventoryCountsBy } = useInventoryCountsHistoryState();
  const {
    data: inventoryCounts,
    isLoading: isLoadingInventoryCounts,
    isFetching: isFetchingInventoryCounts,
  } = useInventoryCounts(
    { orderField: inventoryCountsState.orderField, orderType: inventoryCountsState.orderType },
    inventoryCountsState.page - 1,
    INVENTORY_COUNTS_DEFAULT_TABLE_LIMIT,
  );
  const { setCurrentInventoryCountId, activeStep, setActiveStep, setRestaurantName } = useInventoryCountsContext();
  const [selectedRow, setSelectedRow] = useState<InventoryCountResponse | null>(null);
  const { reset } = useFormContext<InventoryCountsFormValues>();

  const handleContinueInventoryCount = useCallback(
    (original: InventoryCountResponse) => () => {
      setSelectedRow(original);
      continueInventoryCountConfirmModal.onOpen();
    },
    [continueInventoryCountConfirmModal],
  );

  const handleConfirm = useCallback(() => {
    if (selectedRow) {
      setCurrentInventoryCountId(selectedRow.id);
      setActiveStep(activeStep + 1);
      setRestaurantName(selectedRow.restaurant.restaurantName);
      reset();
    }
  }, [activeStep, reset, selectedRow, setActiveStep, setCurrentInventoryCountId, setRestaurantName]);

  const columns = useMemo<ColumnDef<InventoryCountResponse>[]>(
    () => [
      {
        id: 'date',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderInventoryCountsBy(InventoryCountsOrderField.DATE)}
            headerText={translations('inventory_counts_history_date_table_header')}
            showOrder={inventoryCountsState.orderField === InventoryCountsOrderField.DATE}
            orderType={inventoryCountsState.orderType}
          />
        ),
        cell: ({ row }) => <>{formatDateTime(new Date(row.original.createdAt))}</>,
      },
      {
        id: 'expirationDate',
        header: translations('inventory_counts_history_expiration_date_table_header'),
        cell: ({ row }) => <>{formatDateTime(new Date(row.original.endDate))}</>,
      },
      {
        id: 'location',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderInventoryCountsBy(InventoryCountsOrderField.LOCATION)}
            headerText={translations('inventory_counts_history_location_table_header')}
            showOrder={inventoryCountsState.orderField === InventoryCountsOrderField.LOCATION}
            orderType={inventoryCountsState.orderType}
          />
        ),
        cell: ({ row }) => <>{row.original.restaurant.restaurantName}</>,
      },
      {
        id: 'stock',
        header: translations('inventory_counts_history_stock_table_header'),
        cell: ({ row }) => <>{getInventoryCountInventory(row.original)}</>,
      },
      {
        id: 'totalLost',
        header: translations('inventory_counts_history_total_lost_table_header'),
        cell: ({ row }) => (
          <>
            {getInventoryCountLostOverviewValue(
              row.original,
              translations('inventory_counts_history_total_lost_table_header'),
            )}
          </>
        ),
      },
      {
        id: 'invoice',
        header: translations('inventory_counts_history_invoice_table_header'),
        cell: ({ row }) => {
          if (!row.original.invoiceFileUrl) {
            return <>{INVENTORY_COUNT_EMPTY_TABLE_VALUE}</>;
          } else {
            return (
              <LinkButton isDownload to={row.original.invoiceFileUrl} variant={'transparent'}>
                <Image cursor="pointer" src={getIconPath('pdf_icon.svg')} />
              </LinkButton>
            );
          }
        },
      },
      {
        id: 'status',
        header: () => (
          <TableHeaderWithOrder
            onClick={handleOrderInventoryCountsBy(InventoryCountsOrderField.STATUS)}
            headerText={translations('inventory_counts_history_status_table_header')}
            showOrder={inventoryCountsState.orderField === InventoryCountsOrderField.STATUS}
            orderType={inventoryCountsState.orderType}
          />
        ),
        cell: ({ row }) => {
          if (row.original.status === InventoryCountStatus.INVENTORY_IN_PROGRESS) {
            return (
              <Button
                variant={'orangeSolid'}
                fontSize={14}
                py={1}
                px={2}
                m={0}
                h={'auto'}
                onClick={handleContinueInventoryCount(row.original)}
              >
                {translations(getStatusTranslationKey(row.original.status))}
              </Button>
            );
          }
          return <>{translations(getStatusTranslationKey(row.original.status))}</>;
        },
      },
    ],
    [
      handleContinueInventoryCount,
      handleOrderInventoryCountsBy,
      inventoryCountsState.orderField,
      inventoryCountsState.orderType,
      translations,
    ],
  );
  return (
    <>
      <Box px={2} w={'100%'} py={7}>
        <Heading variant="pageHeading" textAlign="left" ml={0} pt={0}>
          {translations('inventory_counts_overview_heading')}
        </Heading>
        <Box py={3}>
          <PageSectionHeading>{translations('inventory_counts_history_heading')}</PageSectionHeading>
        </Box>
        <Box px={[1]} pt={2} w={'100%'}>
          <Table
            variant={'inventoryOverview'}
            isLoading={isLoadingInventoryCounts || isFetchingInventoryCounts}
            type={TableType.PAGINATED}
            data={inventoryCounts?.items || []}
            columns={columns}
            page={inventoryCountsState.page}
            onPageChanged={handlePageChange}
            totalPages={inventoryCounts?.totalPages || 0}
            withBorder
          />
        </Box>
      </Box>
      <Dialog
        leastDestructiveRef={confirmContinueCancelRef}
        isOpen={continueInventoryCountConfirmModal.isOpen}
        onClose={continueInventoryCountConfirmModal.onClose}
        onConfirm={handleConfirm}
        confirmText={translations('inventory_counts_continue_confirm_button_label')}
        cancelText={translations('cancel')}
      >
        <Text fontSize={'20px'}>{translations('inventory_counts_continue_confirm')}</Text>
      </Dialog>
    </>
  );
};
