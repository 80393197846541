import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useHtmlTranslations } from '../../../contexts/LocalizationContext';

interface RetterRelevosCreatedModalProps {
  isOpen: boolean;
  onClose: () => void;
  restaurantsNames: string[];
}

export const RetterRelevosCreatedModal = ({ isOpen, onClose, restaurantsNames }: RetterRelevosCreatedModalProps) => {
  const htmlTranslations = useHtmlTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>✅ {htmlTranslations('retter_relevo_created_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={4}>
          <Text mb={2}>
            {restaurantsNames.length === 1
              ? htmlTranslations('retter_relevo_created_singular_modal_body', {
                  '{{restaurantsNames}}': restaurantsNames.join(', '),
                })
              : htmlTranslations('retter_relevo_created_plural_modal_body', {
                  '{{restaurantsNames}}': restaurantsNames.join(', '),
                })}
          </Text>
          <Button width={'100%'} mb={2} onClick={onClose}>
            {htmlTranslations('retter_relevo_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
