import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import colors from '../../../../styles/colors';
import { DeactivatedReturnCode } from '../../context/model/ReturnCodesResult';
import { stringToDate } from '../../utils';

type ReturnCodesManageModalLastDeactivatedCodeProps = {
  deactivatedCode: DeactivatedReturnCode;
  lastDeactivatedCodeHeader: string;
};

export const ReturnCodesManageModalLastDeactivatedCode = ({
  deactivatedCode,
  lastDeactivatedCodeHeader,
}: ReturnCodesManageModalLastDeactivatedCodeProps) => (
  <>
    <Text mb={2} fontSize={'large'} fontWeight={'bold'}>
      {lastDeactivatedCodeHeader}
    </Text>
    <Box
      key={deactivatedCode.name}
      borderRadius="md"
      padding={4}
      bg={colors.grey[40]}
      mb={'10px'}
      color={colors.grey[600]}
    >
      <HStack justify="space-between" mb={2}>
        <Text fontWeight={'bold'} fontSize={'medium'}>
          {deactivatedCode.name}
        </Text>
      </HStack>
      <Text>Deactivated at: {stringToDate(deactivatedCode.deactivatedAt)}</Text>
      <Text>Type: {deactivatedCode.type}</Text>
    </Box>
  </>
);
