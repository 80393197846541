import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { CreateRetterRelevo } from './CreateRetterRelevo';
import { RetterRelevoHowItWorks } from './RetterRelevoHowItWorks';

export const RetterRelevoManagement = () => {
  const translations = useTranslations();
  return (
    <Flex direction={'row'}>
      <Flex width={['100%', '100%', '50%', '50%']} m={2}>
        <CreateRetterRelevo
          triggerButton={
            <Button variant="orangeSolid" width={'100%'}>
              {translations('retter_relevos_create')}
            </Button>
          }
        />
      </Flex>
      <Flex width={['100%', '100%', '50%', '50%']} m={2}>
        <RetterRelevoHowItWorks />
      </Flex>
    </Flex>
  );
};
