import React from 'react';
import { Center, Flex, Spinner } from '@chakra-ui/react';

import { MainPageContent } from '../../common/components/MainPageContent';
import MainPageHeading from '../../common/components/MainPageHeading/MainPageHeading';
import { useTranslations } from '../../contexts/LocalizationContext';
import { useRetterRelevos } from '../../services/retter-relevos';
import { RETTER_RELEVOS_DEFAULT_TABLE_LIMIT } from './utils/retter-relevos.consts';
import { RetterRelevoManagement } from './RetterRelevoManagement';
import RetterRelevosOfferCard from './RetterRelevosOfferCard';

export const RetterRelevos = () => {
  const translations = useTranslations();
  const {
    data: retterRelevos,
    isLoading: isLoadingRetterRelevos,
    isFetching: isFetchingRetterRelevos,
  } = useRetterRelevos(0, RETTER_RELEVOS_DEFAULT_TABLE_LIMIT);

  return (
    <MainPageContent>
      <MainPageHeading>{translations('retter_relevos_header')}</MainPageHeading>
      <Flex width="100%" direction="column">
        <Flex width="100%" direction="column" bg="beige.50" p={2}>
          <Flex display={['block', 'block', 'none', 'none', 'none']} width={'100%'}>
            <RetterRelevoManagement />
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            <Flex width={['100%', '100%', '50%', '50%']}>{/* <RetterRelevoFilters /> */}</Flex>
            <Flex display={['none', 'none', 'block', 'block', 'block']} justifyContent="flex-end" alignItems={'end'}>
              <RetterRelevoManagement />
            </Flex>
          </Flex>
        </Flex>
        <Flex width="100%">
          {!isLoadingRetterRelevos && !isFetchingRetterRelevos ? (
            <Flex p={4} wrap="wrap" justify="flex-start" gap={6}>
              {retterRelevos?.items.map((retterRelevo) => (
                <RetterRelevosOfferCard key={retterRelevo.id} retterRelevo={retterRelevo} />
              ))}
            </Flex>
          ) : (
            <Center>
              <Spinner />
            </Center>
          )}
        </Flex>
      </Flex>
    </MainPageContent>
  );
};
