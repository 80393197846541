import React, { ChangeEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Image, Input, InputGroup, InputLeftElement, Text, useDisclosure } from '@chakra-ui/react';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreate } from '../ReturnCodesCreate/ReturnCodesCreate';
import { ReturnCodesFiltersModal } from '../ReturnCodesFilters/ReturnCodesFiltersModal';
import { ReturnCodesHowItWorksModal } from '../ReturnCodesHowItWorks/ReturnCodesHowItWorksModal';

type ReturnCodesFiltersModalProps = {
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
  display: string[];
};

export const ReturnCodesTableMediumHeading = ({
  refetchLocations,
  refetchReturnCodes,
  display,
}: ReturnCodesFiltersModalProps) => {
  const { returnCodesState, handleSetSearchLocationText } = useReturnCodesContext();
  const translations = useTranslations();
  const { onClose, isOpen, onOpen } = useDisclosure();

  const handleInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetSearchLocationText(event.target.value);
  };

  return (
    <Flex width="100%" direction="column" display={display}>
      <Flex width="100%" direction="column" bg="beige.50" p={2}>
        <Flex width="100%" justifyContent="space-between">
          <Flex width="100%" justifyContent="flex-end" alignItems={'end'}>
            <Box width="100%" m={2}>
              <Flex align="center" gap={2} width="100%">
                <InputGroup width="100%">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="grey.200" />
                  </InputLeftElement>
                  <Input
                    aria-label={translations('search_location')}
                    value={returnCodesState.searchLocation}
                    variant="whiteFilled"
                    placeholder={translations('return_codes_searchbox_placeholder')}
                    onChange={handleInputOnChange}
                    mr={5}
                    width="100%"
                  />
                  <Button color={'black'} bgColor={'white'} onClick={onOpen} pl={'35px'} pr={'35px'}>
                    <Image src={getIconPath('filters.svg')} mr={'10px'}></Image>
                    <Text>Filters</Text>
                  </Button>
                  <ReturnCodesFiltersModal isOpen={isOpen} onClose={onClose}></ReturnCodesFiltersModal>
                </InputGroup>
                <ReturnCodesCreate
                  refetchLocations={refetchLocations}
                  refetchReturnCodes={refetchReturnCodes}
                ></ReturnCodesCreate>
                <ReturnCodesHowItWorksModal />
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Flex direction={'row'}>
          <Flex width="100%" m={2}></Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
