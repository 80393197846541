import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export const downloadZip = async (files: { name: string; blob: Blob }[], zipFileName: string) => {
  const zip = new JSZip();
  for (const file of files) {
    zip.file(file.name, file.blob);
  }
  zip.generateAsync({ type: 'blob' }).then((content) => saveAs(content, zipFileName));
};
