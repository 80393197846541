import React from 'react';
import { Box } from '@chakra-ui/react';

import colors from '../../../../styles/colors';

type FilterBadgeProps = {
  count: number;
};

export const FilterBadge: React.FC<FilterBadgeProps> = ({ count }) => {
  if (count <= 0) return null; // Prevent rendering if count is 0

  return (
    <Box
      bg={colors.orange['500']}
      color="black"
      width="20px"
      height="20px"
      borderRadius="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
      ml="6px"
      px="6px"
      fontSize="small"
    >
      {count}
    </Box>
  );
};
