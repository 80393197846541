import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesResult } from '../../context/model/ReturnCodesResult';
import { visibleScrollStyle } from '../../utils';
import { ReturnCodesManageModalActiveCode } from './ReturnCodesManageModalActiveCode';
import { ReturnCodesManageModalLastDeactivatedCode } from './ReturnCodesManageModalLastDeactivatedCode';

type ReturnCodesManageModalProps = {
  returnCodesData?: ReturnCodesResult;
  isReturnCodesDataFetching: boolean;
  refetchReturnCodes: () => void;
  refetchLocations: () => void;
};

export const ReturnCodesManageModal = ({
  returnCodesData,
  isReturnCodesDataFetching,
  refetchReturnCodes,
  refetchLocations,
}: ReturnCodesManageModalProps) => {
  const translations = useTranslations();
  const { returnCodesState, handleOpenCreateModalAndOverrideLocation, handleCloseManageModal } =
    useReturnCodesContext();

  const location = returnCodesState.locations.find((l) => l.locationId === returnCodesState.locationIdToManage);

  return (
    <Modal
      isOpen={returnCodesState.isManageModalOpen}
      onClose={() => handleCloseManageModal()}
      size={['sm', 'sm', 'md', 'md', 'md']}
    >
      <ModalOverlay />
      <ModalContent h="auto" maxH="80%" minH="20%" position="fixed" bottom="1%" right="1%" transform="none">
        <ModalHeader>{translations('return_codes_manage_modal_header')}</ModalHeader>
        <ModalCloseButton />
        {isReturnCodesDataFetching && <Spinner />}
        <Flex h="100%" display="column" p={4}>
          <Text mb={2} fontSize={'medium'} fontWeight={'bold'}>
            {location?.locationName}
          </Text>
          <Text mb={4} fontSize={'small'}>
            {translations('return_codes_manage_modal_description')}
          </Text>
          {(returnCodesData?.returnCodes || []).length > 0 && (
            <>
              <Text mb={2} fontSize={'large'} fontWeight={'bold'}>
                {(returnCodesData?.returnCodes || []).length === 1
                  ? translations('return_codes_manage_modal_active_code')
                  : translations('return_codes_manage_modal_active_codes')}
              </Text>
              <Box
                maxH="350px"
                minH="50px"
                h="auto"
                overflowY="scroll"
                borderColor="grey.200"
                mb={10}
                sx={visibleScrollStyle}
              >
                {returnCodesData?.returnCodes.map((returnCode) => (
                  <ReturnCodesManageModalActiveCode
                    activeCode={returnCode}
                    refetchLocations={refetchLocations}
                    refetchReuseCodes={refetchReturnCodes}
                    isLastActive={returnCodesData?.returnCodes.length === 1}
                  ></ReturnCodesManageModalActiveCode>
                ))}
              </Box>
            </>
          )}
          {returnCodesData?.lastDeactivatedCode && (
            <ReturnCodesManageModalLastDeactivatedCode
              deactivatedCode={returnCodesData?.lastDeactivatedCode}
              lastDeactivatedCodeHeader={translations('return_codes_manage_modal_last_deactivated_code')}
            ></ReturnCodesManageModalLastDeactivatedCode>
          )}
          {location && (
            <Button
              width={'100%'}
              variant="orangeSolid"
              onClick={() =>
                handleOpenCreateModalAndOverrideLocation({
                  locationId: location?.locationId,
                  locationName: location?.locationName,
                  locationMetaCategories: location?.metaCategories,
                })
              }
            >
              {translations('return_codes_manage_modal_create_new_return_code')}
            </Button>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};
