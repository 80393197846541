import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models/paginated';
import { apiPaths } from '../config/apiPaths';
import { LocationWithIssueType } from '../modules/ReturnCodes/context/model/LocationWithIssueType';
import { ReturnCodeAgeType } from '../modules/ReturnCodes/context/model/ReturnCodeAgeType';
import { ReturnCodeCreateRequest } from '../modules/ReturnCodes/context/model/ReturnCodeCreate.request';
import { ReturnCodeCreateResponse } from '../modules/ReturnCodes/context/model/ReturnCodeCreate.response';
import { ReturnCodesCity } from '../modules/ReturnCodes/context/model/ReturnCodesCity';
import { ReturnCodesLocation } from '../modules/ReturnCodes/context/model/ReturnCodesLocation';
import { ReturnCodesResult } from '../modules/ReturnCodes/context/model/ReturnCodesResult';
import axios from './axios';
import axiosInstance from './axios';

export interface ReturnCodesLocationsFilters {
  searchLocationText: string;
  cityIds: string[];
  returnCodeAge: ReturnCodeAgeType | null;
  locationWithIssueType: LocationWithIssueType | null;
}

export const getRestaurantsWithReturnCodesCount = (
  filters: ReturnCodesLocationsFilters,
  offset: number,
  limit: number,
): Promise<Paginated<ReturnCodesLocation>> => {
  return axios
    .get(apiPaths.RETURN_CODES_RESTAURANTS_WITH_RETURN_CODES_COUNT, {
      params: { offset, limit, ...filters },
    })
    .then(({ data }) => data);
};

export const getCitiesForReturnCodesFilters = (filters: ReturnCodesLocationsFilters): Promise<ReturnCodesCity[]> => {
  return axios
    .get(apiPaths.RETURN_CODES_CITIES_FOR_FILTERS, {
      params: { ...filters },
    })
    .then(({ data }) => data);
};

export const getReturnCodesForRestaurant = (restaurantId: string): Promise<ReturnCodesResult[]> => {
  if (!restaurantId)
    return new Promise((resolve) => {
      resolve([]);
    });

  return axios.get(generatePath(apiPaths.RETURN_CODES_FOR_RESTAURANT, { restaurantId })).then(({ data }) => data);
};

export const createReturnCodes = (request: ReturnCodeCreateRequest): Promise<ReturnCodeCreateResponse> => {
  return axiosInstance.post(apiPaths.RETURN_CODES_BATCH_CREATE, request).then(({ data }) => data);
};

export const deactivateReturnCode = (returnCodeId: string) => {
  return axios.post(generatePath(apiPaths.RETURN_CODES_DEACTIVATE, { returnCodeId })).then(({ data }) => data);
};
