import { useTranslations } from 'contexts/LocalizationContext';
import * as yup from 'yup';

import { RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START } from '../utils/retter-relevos.consts';
import { parsePickupDateAndPickupTimeFromIntoDate } from '../utils/retter-relevos.dates';

export const useRetterRelevoFormValidation = () => {
  const translations = useTranslations();

  return yup.object({
    restaurantIds: yup
      .array()
      .of(yup.string().required(translations('validation_required')))
      .min(
        1,
        translations('validation_too_few_items', {
          '{{minItems}}': '1',
          '{{items}}': translations('validation_location'),
        }),
      )
      .required(translations('validation_required')),
    title: yup
      .string()
      .required(translations('validation_required'))
      .max(100, translations('validation_too_long', { '{{maxLength}}': '100' })),
    price: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError(translations('validation_number'))
      .min(0, translations('validation_greater_then_0')),
    availableAmount: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError(translations('validation_number'))
      .min(0, translations('validation_greater_then_0')),
    pickupDate: yup.date().required(translations('validation_required')),
    pickupTimeFrom: yup
      .string()
      .required(translations('validation_required'))
      .test(
        'is-before-pickupTimeTo',
        translations('retter_relevo_validation_time_from_before_to'),
        function (pickupTimeFrom) {
          const { pickupTimeTo } = this.parent;
          if (!pickupTimeFrom || !pickupTimeTo) return true;
          return pickupTimeFrom < pickupTimeTo;
        },
      )
      .test(
        'is-valid-start-time',
        translations('retter_relevo_validation_minimum_start_time', {
          '{{minHours}}': RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START.toString(),
        }),
        function (pickupTimeFrom) {
          const { pickupDate } = this.parent;

          if (!pickupDate || !pickupTimeFrom) return true;

          const offerStartDate = parsePickupDateAndPickupTimeFromIntoDate(pickupDate, pickupTimeFrom);

          // Get the current date and add 2 hours
          const minStartTime = new Date();
          minStartTime.setHours(minStartTime.getHours() + RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START);

          return offerStartDate.getTime() >= minStartTime.getTime();
        },
      ),
    pickupTimeTo: yup.string().required(translations('validation_required')),
  });
};
