import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

import { LocationWithIssueType } from './model/LocationWithIssueType';
import { ReturnCodeAgeType } from './model/ReturnCodeAgeType';
import { ReturnCodesContext as ReturnCodesContextModel } from './model/ReturnCodesContext';
import { ReturnCodesLocation } from './model/ReturnCodesLocation';
import { SelectedLocation } from './model/ReturnCodesSelectedLocations';
import { ReturnCodesState } from './model/ReturnCodesState';

type ReturnCodesContextProps = {
  children: ReactNode;
};

export const ReturnCodesContext = createContext<ReturnCodesContextModel | null>(null);

export const ReturnCodesContextProvider = ({ children }: ReturnCodesContextProps) => {
  const [returnCodesState, setReturnCodesState] = useState<ReturnCodesState>({
    searchLocation: '',
    searchCity: '',
    allowedCities: [],
    selectedCities: [],
    selectedLocations: [],
    selectedLocationWithIssueType: null,
    selectedReturnCodeAgeType: null,
    overridenLocationToCreate: null,
    locations: [],
    page: 1,
    locationIdToManage: null,
    isCreateModalOpen: false,
    isCreatedModalOpen: false,
    isManageModalOpen: false,
    isDeactivatedModalOpen: false,
    isUnusedModalOpen: false,
    isNoReturnCodeModalOpen: false,
    unusedLocationId: '',
    unusedLocationName: '',
    noReturnCodeLocationId: '',
    noReturnCodeLocationName: '',
    noReturnCodeLocationCategories: [],
    unusedReturnCodes: [],
  });

  const selectedFiltersCount = useMemo(() => {
    return (
      returnCodesState.selectedCities.length +
      (returnCodesState.selectedLocationWithIssueType !== null ? 1 : 0) +
      (returnCodesState.selectedReturnCodeAgeType !== null ? 1 : 0)
    );
  }, [
    returnCodesState.selectedCities,
    returnCodesState.selectedLocationWithIssueType,
    returnCodesState.selectedReturnCodeAgeType,
  ]);

  const checkedAllLocations = useMemo(() => {
    const selectedLocationsIds = returnCodesState.selectedLocations.map((sl) => sl.locationId);
    const availableLocationsIds = returnCodesState.locations.map((l) => l.locationId);
    return availableLocationsIds.every((locationId) => selectedLocationsIds.includes(locationId));
  }, [returnCodesState.selectedLocations, returnCodesState.locations]);

  const handleSetPage = (page: number) => {
    setReturnCodesState({
      ...returnCodesState,
      page,
    });
  };

  const handleSelectCity = (cityId: string) => {
    const cityIdAlreadySelected = returnCodesState.selectedCities.includes(cityId);

    if (cityIdAlreadySelected) {
      setReturnCodesState({
        ...returnCodesState,
        selectedCities: [...returnCodesState.selectedCities.filter((scId) => scId !== cityId)],
        page: 1,
      });
    } else {
      setReturnCodesState({
        ...returnCodesState,
        selectedCities: [...returnCodesState.selectedCities, cityId],
        page: 1,
      });
    }
  };

  const handleSetSearchLocationText = (searchText: string) => {
    setReturnCodesState({
      ...returnCodesState,
      searchLocation: searchText,
      page: 1,
      selectedLocations: [],
    });
  };

  const handleSetSearchCityText = (searchText: string) => {
    setReturnCodesState({
      ...returnCodesState,
      searchCity: searchText,
    });
  };

  const handleLocationCheck = (isChecked: boolean, location: SelectedLocation) => {
    if (isChecked) {
      setReturnCodesState({
        ...returnCodesState,
        selectedLocations: [...returnCodesState.selectedLocations, location],
      });
    } else {
      setReturnCodesState({
        ...returnCodesState,
        selectedLocations: returnCodesState.selectedLocations.filter((sl) => sl.locationId !== location.locationId),
      });
    }
  };

  const handleSetLocations = useCallback((locations: ReturnCodesLocation[]) => {
    setReturnCodesState((prevState) => ({
      ...prevState,
      locations,
    }));
  }, []);

  const handleOpenCreateModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      overridenLocationToCreate: null,
      isCreateModalOpen: true,
      isUnusedModalOpen: false,
      isNoReturnCodeModalOpen: false,
    });
  };

  const handleOpenCreateModalAndOverrideLocation = (location: SelectedLocation) => {
    setReturnCodesState({
      ...returnCodesState,
      overridenLocationToCreate: location,
      isCreateModalOpen: true,
      isUnusedModalOpen: false,
      isNoReturnCodeModalOpen: false,
    });
  };

  const handleCloseCreateModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      overridenLocationToCreate: null,
      isCreateModalOpen: false,
    });
  };

  const handleCloseCreatedModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      isCreatedModalOpen: false,
    });
  };

  const handleCloseCreateModalAfterCreate = () => {
    setReturnCodesState({
      ...returnCodesState,
      isCreateModalOpen: false,
      isManageModalOpen: false,
      isCreatedModalOpen: true,
    });
  };

  const handleCloseManageModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      isManageModalOpen: false,
    });
  };

  const handleOpenManageModal = (locationId: string) => {
    setReturnCodesState({
      ...returnCodesState,
      locationIdToManage: locationId,
      isManageModalOpen: true,
      isUnusedModalOpen: false,
      isNoReturnCodeModalOpen: false,
    });
  };

  const handleSetReturnCodeAgeType = (returnCodeAgeType: ReturnCodeAgeType | null) => {
    setReturnCodesState({
      ...returnCodesState,
      selectedReturnCodeAgeType: returnCodeAgeType,
      selectedLocations: [],
    });
  };

  const handleSetLocationWithIssueType = (locationWithIssueType: LocationWithIssueType | null) => {
    setReturnCodesState({
      ...returnCodesState,
      selectedLocationWithIssueType: locationWithIssueType,
      selectedLocations: [],
    });
  };

  const handleOpenDeactivatedModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      isDeactivatedModalOpen: true,
      isManageModalOpen: false,
    });
  };

  const handleCloseDeactivatedModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      isDeactivatedModalOpen: false,
    });
  };

  const handleResetFilters = () => {
    setReturnCodesState({
      ...returnCodesState,
      selectedLocationWithIssueType: null,
      selectedReturnCodeAgeType: null,
      selectedCities: [],
      searchCity: '',
    });
  };

  const handleOpenUnusedModal = (
    locationId: string,
    locationName: string,
    unusedReturnCodes: { id: string; name: string }[],
  ) => {
    setReturnCodesState({
      ...returnCodesState,
      unusedLocationId: locationId,
      unusedLocationName: locationName,
      unusedReturnCodes: unusedReturnCodes,
      isUnusedModalOpen: true,
    });
  };

  const handleCloseUnusedModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      unusedLocationId: '',
      unusedLocationName: '',
      unusedReturnCodes: [],
      isUnusedModalOpen: false,
    });
  };

  const handleOpenNoReturnCodeModal = (locationId: string, locationName: string, locationCategories: string[]) => {
    setReturnCodesState({
      ...returnCodesState,
      noReturnCodeLocationId: locationId,
      noReturnCodeLocationName: locationName,
      noReturnCodeLocationCategories: locationCategories,
      isNoReturnCodeModalOpen: true,
    });
  };

  const handleCloseNoReturnCodeModal = () => {
    setReturnCodesState({
      ...returnCodesState,
      noReturnCodeLocationId: '',
      noReturnCodeLocationName: '',
      noReturnCodeLocationCategories: [],
      isNoReturnCodeModalOpen: false,
    });
  };

  const handleAllLocationsCheck = (isChecked: boolean) => {
    if (isChecked) {
      const selectedLocationsIds = returnCodesState.selectedLocations.map((sl) => sl.locationId);
      const availableLocationsIds = returnCodesState.locations.map((l) => l.locationId);
      const locationsToAdd = availableLocationsIds
        .filter((locationId) => !selectedLocationsIds.includes(locationId))
        .map(
          (locationId) => returnCodesState.locations.find((l) => l.locationId === locationId) as ReturnCodesLocation,
        );

      setReturnCodesState({
        ...returnCodesState,
        selectedLocations: [
          ...returnCodesState.selectedLocations,
          ...locationsToAdd.map((l) => ({
            locationId: l.locationId,
            locationName: l.locationName,
            locationMetaCategories: l.metaCategories,
          })),
        ],
      });
    } else {
      setReturnCodesState({
        ...returnCodesState,
        selectedLocations: [],
      });
    }
  };

  return (
    <ReturnCodesContext.Provider
      value={{
        returnCodesState,
        handleSelectCity,
        handleSetSearchLocationText,
        handleSetSearchCityText,
        handleLocationCheck,
        handleSetLocations,
        handleSetPage,
        handleOpenCreateModal,
        handleOpenCreateModalAndOverrideLocation,
        handleCloseCreateModal,
        handleCloseCreatedModal,
        handleCloseManageModal,
        handleOpenManageModal,
        handleCloseCreateModalAfterCreate,
        handleSetReturnCodeAgeType,
        handleSetLocationWithIssueType,
        handleOpenDeactivatedModal,
        handleCloseDeactivatedModal,
        handleResetFilters,
        handleOpenUnusedModal,
        handleCloseUnusedModal,
        handleOpenNoReturnCodeModal,
        handleCloseNoReturnCodeModal,
        handleAllLocationsCheck,
        selectedFiltersCount,
        checkedAllLocations,
      }}
    >
      {children}
    </ReturnCodesContext.Provider>
  );
};
