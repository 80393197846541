import { ChangeEvent } from 'react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { format, parseISO } from 'date-fns';

import { FileExtension } from '../../common/models/enums/file.extension';
import { RETURN_CODES_GRACE_PERIOD_MAX_VALUE, RETURN_CODES_GRACE_PERIOD_MIN_VALUE } from './ReturnCodes.consts';

export const stringToDate = (dateString: string) => format(parseISO(dateString), 'dd/MM/yyyy');
export const getReturnCodePosterFileName = (returnCodeName: string) => `${returnCodeName}-poster.${FileExtension.PDF}`;

export const handleGracePeriodInputChange =
  (setGracePeriod: (value: number) => void) => (value: ChangeEvent<HTMLInputElement>) => {
    let number = Number(value.target.value);
    if (number < RETURN_CODES_GRACE_PERIOD_MIN_VALUE) number = RETURN_CODES_GRACE_PERIOD_MIN_VALUE;
    if (number > RETURN_CODES_GRACE_PERIOD_MAX_VALUE) number = RETURN_CODES_GRACE_PERIOD_MAX_VALUE;

    setGracePeriod(number);
  };

export const visibleScrollStyle: SystemStyleObject = {
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
};
