import * as React from 'react';
import { JSX } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from '@rollbar/react';
import AppLayout from 'common/components/AppLayout';
import ProtectedRoute from 'common/components/ProtectedRoute';
import { Routes } from 'config/routes';
import { LocalizationContextProvider } from 'contexts/LocalizationContext';
import { SessionContextProvider } from 'contexts/SessionContext';
import ResetPassword from 'modules/ResetPassword';
import SignIn from 'modules/SignIn';
import { Configuration } from 'rollbar';
import Fonts from 'styles/fonts';
import theme from 'styles/theme';
import { EmailPasswordAuth } from 'supertokens-auth-react/lib/build/recipe/emailpassword';
import initSuperTokens from 'utils/supertokens';

import './App.css';

import ScanditSDKContextProvider from './common/components/Scanner/ScanditSDKContext';
import Toast from './common/components/Toast/Toast';
import { ROUTER_ITEMS } from './config/router-items';
import CustomerCreationFormStepper from './modules/CustomerCreation/CustomerCreationFormStepper';

initSuperTokens();
const queryClient = new QueryClient();

const rollbarConfig: Configuration | (() => Configuration) = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ENV,
  enabled: !!process.env.REACT_APP_ENV && ['development', 'production'].includes(process.env.REACT_APP_ENV),
  payload: {
    client: {
      javascript: {
        code_version: process.env.REACT_APP_VERSION, // This variable is provided by CI/CD with current hash commit
        source_map_enabled: true,
      },
    },
  },
};

export const App = (): JSX.Element => (
  <div translate={'no'}>
    <Provider config={rollbarConfig}>
      <ChakraProvider theme={theme}>
        <Toast />
        <Fonts />
        <EmailPasswordAuth requireAuth={false}>
          <QueryClientProvider client={queryClient}>
            <SessionContextProvider>
              <LocalizationContextProvider>
                <ScanditSDKContextProvider>
                  <Router>
                    <AppLayout>
                      <ReactRouterRoutes>
                        {ROUTER_ITEMS.map(({ path, element, roles }) => (
                          <Route key={path} path={path} element={<ProtectedRoute roles={roles} element={element} />} />
                        ))}

                        <Route path={Routes.SIGNIN_PATH} element={<SignIn />} />
                        <Route path={Routes.RESET_PASSWORD_PATH} element={<ResetPassword />} />
                        <Route path={Routes.CREATE_CUSTOMER_PATH} element={<CustomerCreationFormStepper />} />
                      </ReactRouterRoutes>
                    </AppLayout>
                  </Router>
                </ScanditSDKContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </LocalizationContextProvider>
            </SessionContextProvider>
          </QueryClientProvider>
        </EmailPasswordAuth>
      </ChakraProvider>
    </Provider>
  </div>
);
