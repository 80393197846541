import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 2,
    borderColor: 'orange.500',
    borderRadius: 0,
  },
});
const disabledOrange = definePartsStyle({
  control: {
    borderRadius: 'sm',
    border: '2px solid',
    borderColor: 'orange.500',
    bg: 'white',
    _checked: {
      _disabled: {
        bg: 'orange.500',
        borderColor: 'orange.500',
        color: 'white',
        opacity: 1,
      },
    },
    _hover: {
      borderColor: 'orange.500',
    },
    _disabled: {
      bg: 'white',
      borderColor: 'orange.500',
      cursor: 'pointer',
    },
  },
});

const Checkbox = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'md',
  },
  variants: {
    disabledOrange,
  },
});

export default Checkbox;
