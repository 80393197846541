import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';

export const ReturnCodesNoReturnCodeModal = () => {
  const translations = useTranslations();
  const { returnCodesState, handleCloseNoReturnCodeModal, handleOpenCreateModalAndOverrideLocation } =
    useReturnCodesContext();

  return (
    <Modal isOpen={returnCodesState.isNoReturnCodeModalOpen} onClose={handleCloseNoReturnCodeModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translations('return_codes_no_return_code_modal_header')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          <Text mb={2} fontSize={'small'}>
            {translations('return_codes_no_return_code_modal_content', {
              '{{restaurantName}}': returnCodesState.noReturnCodeLocationName,
            })}
          </Text>
          <Button
            variant="orangeSolid"
            width={'100%'}
            mb={2}
            onClick={() => {
              handleCloseNoReturnCodeModal();
              handleOpenCreateModalAndOverrideLocation({
                locationId: returnCodesState.noReturnCodeLocationId,
                locationName: returnCodesState.noReturnCodeLocationName,
                locationMetaCategories: returnCodesState.noReturnCodeLocationCategories,
              });
            }}
          >
            {translations('return_codes_no_return_code_modal_add_return_code_button')}
          </Button>
          <Button width={'100%'} mb={2} onClick={handleCloseNoReturnCodeModal}>
            {translations('return_codes_no_return_code_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
