import React from 'react';
import { Button, Tooltip } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesCreatedModal } from '../ReturnCodesCreated/ReturnCodesCreatedModal';
import { ReturnCodesCreateModal } from './ReturnCodesCreateModal';

type ReturnCodesCreateProps = {
  refetchLocations: () => void;
  refetchReturnCodes: () => void;
};

type CreateButtonProps = {
  buttonText: string;
  checkedLocationsCount: number;
  handleOpenCreateModal: () => void;
  tooltipLabel: string;
};

const ButtonWithTooltipIfInactive = ({
  buttonText,
  checkedLocationsCount,
  handleOpenCreateModal,
  tooltipLabel,
}: CreateButtonProps) => {
  return checkedLocationsCount === 0 ? (
    <Tooltip label={tooltipLabel}>
      <Button
        isDisabled={checkedLocationsCount === 0}
        variant="orangeSolid"
        onClick={handleOpenCreateModal}
        width={'100%'}
      >
        {buttonText}
      </Button>
    </Tooltip>
  ) : (
    <Button
      isDisabled={checkedLocationsCount === 0}
      variant="orangeSolid"
      onClick={handleOpenCreateModal}
      width={'100%'}
    >
      {buttonText}
    </Button>
  );
};

export const ReturnCodesCreate = ({ refetchLocations, refetchReturnCodes }: ReturnCodesCreateProps) => {
  const translations = useTranslations();
  const { returnCodesState, handleOpenCreateModal } = useReturnCodesContext();
  const checkedLocationsCount = returnCodesState.selectedLocations.length;

  const buttonText =
    checkedLocationsCount === 1
      ? translations('return_codes_create_return_code_for_single_location_button')
      : translations('return_codes_create_return_code_for_locations_button', {
          '{{locationCount}}':
            checkedLocationsCount > 0
              ? checkedLocationsCount.toString()
              : translations('return_codes_create_return_code_for_locations_button_multiple'),
        });

  return (
    <>
      <ButtonWithTooltipIfInactive
        buttonText={buttonText}
        checkedLocationsCount={checkedLocationsCount}
        handleOpenCreateModal={handleOpenCreateModal}
        tooltipLabel={translations('return_codes_create_return_code_for_locations_button_hover')}
      ></ButtonWithTooltipIfInactive>
      <ReturnCodesCreateModal
        refetchLocations={refetchLocations}
        refetchReturnCodes={refetchReturnCodes}
      ></ReturnCodesCreateModal>
      <ReturnCodesCreatedModal></ReturnCodesCreatedModal>
    </>
  );
};
