import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';

export const ReturnCodesUnusedReturnCodeModal = () => {
  const translations = useTranslations();
  const { returnCodesState, handleCloseUnusedModal, handleOpenManageModal } = useReturnCodesContext();

  return (
    <Modal isOpen={returnCodesState.isUnusedModalOpen} onClose={handleCloseUnusedModal} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{translations('return_codes_unused_code_modal_header')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={2}>
          {returnCodesState.unusedReturnCodes.map(({ id, name }) => (
            <Text key={id} fontSize={'small'}>
              {name}
            </Text>
          ))}

          <Text mb={2} mt={5} fontSize={'small'}>
            {translations('return_codes_unused_code_modal_content', {
              '{{restaurantName}}': returnCodesState.unusedLocationName,
            })}
          </Text>
          <Button
            variant="orangeSolid"
            width={'100%'}
            mb={2}
            onClick={() => {
              handleOpenManageModal(returnCodesState.unusedLocationId);
            }}
          >
            {translations('return_codes_unused_code_modal_manage_return_codes_button')}
          </Button>
          <Button width={'100%'} mb={2} onClick={handleCloseUnusedModal}>
            {translations('return_codes_unused_code_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
