import React from 'react';
import { pdf } from '@react-pdf/renderer';

import { downloadZip } from '../../../../utils/downloadZip';
import { ReturnCodeCreateResponse } from '../../context/model/ReturnCodeCreate.response';
import { RETURN_POSTERS_ZIP_FILE_NAME } from '../../ReturnCodes.consts';
import { getReturnCodePosterFileName } from '../../utils';
import PdfDocument from '../ReturnPosters/PdfDocument';

export const handleDownloadMultipleReturnQrPosters = async (data: ReturnCodeCreateResponse) => {
  const files = await Promise.all(
    data.createdReturnCodes.map(async (returnCode) => {
      const blob = await pdf(
        <PdfDocument
          returnCode={returnCode.returnCode}
          restaurant={{
            restaurantName: returnCode.restaurant.restaurantName || '',
            metaCategories: returnCode.restaurant.metaCategories || [],
          }}
        />,
      ).toBlob();
      return {
        name: getReturnCodePosterFileName(returnCode.returnCode.name),
        blob,
      };
    }),
  );
  await downloadZip(files, RETURN_POSTERS_ZIP_FILE_NAME);
};
