import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { INVENTORY_COUNTS_QUERY_KEY } from '../api/apiConsts';
import {
  createInventoryCount,
  createInventoryCountSnapshot,
  getInventoryCount,
  getInventoryCountMissingItems,
  getInventoryCounts,
  getInventoryCountSnapshot,
  submitInventoryCount,
  updateInventoryCount,
  updateItemsInInventoryCount,
} from '../api/inventory-counts';
import { HttpException } from '../common/models/httpException';
import { Paginated } from '../common/models/paginated';
import { useTranslations } from '../contexts/LocalizationContext';
import { InventoryCountMissingItemResponse } from '../modules/InventoryCounts/model/inventory-count-missing-item-response';
import { InventoryCountResponse } from '../modules/InventoryCounts/model/inventory-count-response';
import { InventoryCountsQueryRequest } from '../modules/InventoryCounts/model/inventory-counts-query-request';
import { InventoryCountsSubmitRequest } from '../modules/InventoryCounts/model/inventory-counts-submit-request';
import { InventoryCountsSubmitResponse } from '../modules/InventoryCounts/model/inventory-counts-submit-response';
import { InventoryCountsUpdateItemsResponse } from '../modules/InventoryCounts/model/inventory-counts-update-items-response';
import { InventoryCountsUpdateRequest } from '../modules/InventoryCounts/model/inventory-counts-update-request';

const getInventoryCountsQueryKey = (
  offset: number,
  limit: number,
  inventoryCountsQueryParams: InventoryCountsQueryRequest,
) => [INVENTORY_COUNTS_QUERY_KEY, { offset, limit, ...inventoryCountsQueryParams }];
const getInventoryCountQueryKey = (inventoryCountId: string) => [INVENTORY_COUNTS_QUERY_KEY, inventoryCountId];
const getInventoryCountMissingItemsQueryKey = (inventoryCountId: string, offset: number, limit: number) => [
  INVENTORY_COUNTS_QUERY_KEY,
  inventoryCountId,
  limit,
  offset,
];

export const useInventoryCounts = (
  inventoryCountsQueryParams: InventoryCountsQueryRequest,
  offset: number,
  limit: number,
): QueryObserverResult<Paginated<InventoryCountResponse>, AxiosError> => {
  return useQuery(
    getInventoryCountsQueryKey(offset, limit, inventoryCountsQueryParams),
    () => getInventoryCounts(inventoryCountsQueryParams, offset, limit),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
};

export const useInventoryCountMissingItems = (
  inventoryCountId: string,
  offset: number,
  limit: number,
): QueryObserverResult<Paginated<InventoryCountMissingItemResponse>, AxiosError> => {
  return useQuery(
    getInventoryCountMissingItemsQueryKey(inventoryCountId, offset, limit),
    () => getInventoryCountMissingItems(inventoryCountId, offset, limit),
    { refetchOnWindowFocus: false, keepPreviousData: true, enabled: !!inventoryCountId.length },
  );
};

export const useCreateInventoryCount = (): UseMutationResult<
  InventoryCountResponse,
  AxiosError<HttpException>,
  { restaurantId: string }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(({ restaurantId }: { restaurantId: string }) => createInventoryCount(restaurantId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(INVENTORY_COUNTS_QUERY_KEY);
    },
    onError: (err) => {
      toast.error(err.response?.data.message || translations('something_went_wrong'));
    },
  });
};

export const useUpdateItemsInInventoryCount = (): UseMutationResult<
  InventoryCountsUpdateItemsResponse,
  AxiosError<HttpException>,
  { inventoryCountId: string; updateRequest: InventoryCountsUpdateRequest }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ inventoryCountId, updateRequest }: { inventoryCountId: string; updateRequest: InventoryCountsUpdateRequest }) =>
      updateItemsInInventoryCount(inventoryCountId, updateRequest),
    {
      onSuccess: async (response) => {
        if (response.itemAssignedToRestaurant) {
          toast.info(translations('inventory_counts_item_assigned_to_restaurant'));
        }
        await queryClient.invalidateQueries(INVENTORY_COUNTS_QUERY_KEY);
      },
      onError: (err) => {
        toast.error(err.response?.data.message || translations('something_went_wrong'));
      },
    },
  );
};

export const useInventoryCount = (
  inventoryCountId: string,
): QueryObserverResult<InventoryCountResponse, AxiosError> => {
  return useQuery(getInventoryCountQueryKey(inventoryCountId), () => getInventoryCount(inventoryCountId), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!inventoryCountId.length,
  });
};

export const useCreateInventoryCountSnapshot = (): UseMutationResult<
  InventoryCountResponse,
  AxiosError<HttpException>,
  { inventoryCountId: string }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ inventoryCountId }: { inventoryCountId: string }) => createInventoryCountSnapshot(inventoryCountId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(INVENTORY_COUNTS_QUERY_KEY);
      },
      onError: (err) => {
        toast.error(err.response?.data.message || translations('something_went_wrong'));
      },
    },
  );
};

export const useInventoryCountSnapshot = (
  inventoryCountId: string,
): QueryObserverResult<InventoryCountResponse, AxiosError> => {
  return useQuery(getInventoryCountQueryKey(inventoryCountId), () => getInventoryCountSnapshot(inventoryCountId), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!inventoryCountId.length,
  });
};

export const useSubmitInventoryCount = (): UseMutationResult<
  InventoryCountsSubmitResponse,
  AxiosError<HttpException>,
  { inventoryCountId: string; submitRequest: InventoryCountsSubmitRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ inventoryCountId, submitRequest }: { inventoryCountId: string; submitRequest: InventoryCountsSubmitRequest }) =>
      submitInventoryCount(inventoryCountId, submitRequest),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(INVENTORY_COUNTS_QUERY_KEY);
      },
    },
  );
};
export const useUpdateInventoryCount = (): UseMutationResult<
  InventoryCountsSubmitResponse,
  AxiosError<HttpException>,
  { inventoryCountId: string; submitRequest: InventoryCountsSubmitRequest }
> => {
  return useMutation(
    ({ inventoryCountId, submitRequest }: { inventoryCountId: string; submitRequest: InventoryCountsSubmitRequest }) =>
      updateInventoryCount(inventoryCountId, submitRequest),
  );
};
