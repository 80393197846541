import { InventoryCountStatus } from '../model/enums/inventory-count-status.enum';
import { InventoryCountResponse } from '../model/inventory-count-response';
import { INVENTORY_COUNT_EMPTY_TABLE_VALUE } from './inventory-counts.consts';

export const getStatusTranslationKey = (status: InventoryCountStatus) => {
  switch (status) {
    case InventoryCountStatus.INVENTORY_IN_PROGRESS:
      return 'inventory_counts_inventory_status_inventory_in_progress';
    case InventoryCountStatus.INVENTORY_FINISHED:
      return 'inventory_counts_inventory_status_inventory_finished';
    case InventoryCountStatus.INVENTORY_REJECTED:
      return 'inventory_counts_inventory_status_inventory_rejected';
    case InventoryCountStatus.INVOICE_AVAILABLE:
      return 'inventory_counts_inventory_status_invoice_available';
    case InventoryCountStatus.INVOICE_OPEN:
      return 'inventory_counts_inventory_status_invoice_open';
    case InventoryCountStatus.INVOICE_REJECTED:
      return 'inventory_counts_inventory_status_invoice_rejected';
    case InventoryCountStatus.INVOICE_PAID:
      return 'inventory_counts_inventory_status_invoice_paid';
    case InventoryCountStatus.UNDER_REVIEW:
      return 'inventory_counts_inventory_status_under_review';
    case InventoryCountStatus.INVENTORY_DELETED:
      return 'inventory_counts_inventory_status_inventory_deleted';
    default: {
      const exhaustiveCheck: never = status;
      throw new Error(exhaustiveCheck);
    }
  }
};

export const getInventoryCountLostOverviewValue = (original: InventoryCountResponse, lostText: string) => {
  if (original.status === InventoryCountStatus.INVENTORY_IN_PROGRESS) {
    return INVENTORY_COUNT_EMPTY_TABLE_VALUE;
  } else {
    if (original.actualStock !== null && !!original.shouldStock) {
      const lostRate = (((original.shouldStock - original.actualStock) / original.shouldStock) * 100).toFixed(2);
      return `${lostRate}% (${original.shouldStock - original.actualStock} ${lostText})`;
    } else {
      return INVENTORY_COUNT_EMPTY_TABLE_VALUE;
    }
  }
};

export const getInventoryCountInventory = (inventoryCount: InventoryCountResponse): string => {
  if (inventoryCount.shouldStock === null || inventoryCount.actualStock === null) {
    return INVENTORY_COUNT_EMPTY_TABLE_VALUE;
  }
  return `${inventoryCount.shouldStock}/${inventoryCount.actualStock}`;
};
