import React, { JSX, useCallback } from 'react';
import { Document, G, Image, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';

import relevoGlass from '../../../../assets/images/Relevo-GLAS_Rückgabeschild_A4_LEER.png';
import relevoGlassEnhanced from '../../../../assets/images/Relevo-GLASS_Rückgabeschild_A4_LEER_MI.png';
import relevoClassic from '../../../../assets/images/Relevo-KLASSIK_Rückgabeschild_A4_LEER.png';
import relevoClassicEnhanced from '../../../../assets/images/Relevo-KLASSIK_Rückgabeschild_A4_LEER_MI.png';
import colors from '../../../../styles/colors';
import { formatDate } from '../../../../utils/dateHelper';
import { getQRCodeSrc, isEnhancedUId } from '../../../../utils/qrCodesHelper';
import { ReturnCodeType } from '../../context/model/ReturnCodeType';

export enum UIdType {
  PRODUCT = 'PRODUCT',
  RETURN = 'RETURN',
}

interface MetaCategory {
  id: string;
}

interface Restaurant {
  restaurantName: string;
  metaCategories: MetaCategory[];
}

interface ReturnCode {
  uId: string;
  qrCode: string;
  type: ReturnCodeType;
  recordCreatedAt: string;
}
type PdfDocumentProps = {
  returnCode: ReturnCode;
  restaurant?: Restaurant;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
  },
  view: {
    display: 'flex',
    padding: '4px',
  },
  posterTemplate: {},
  QRCode: {
    position: 'absolute',
    width: '23%',
    marginTop: '375px',
    marginLeft: '230px',
  },
  dateText: {
    fontSize: '10px',
    position: 'absolute',
    marginTop: '726px',
    marginLeft: '33px',
    color: colors.grey[200],
  },
  text: {
    fontSize: '12px',
    fontWeight: 'bold',
    position: 'absolute',
    marginTop: '742px',
    marginLeft: '48px',
  },
  clockIcon: {
    position: 'absolute',
    marginTop: '743px',
    marginLeft: '33px',
    width: 13,
    height: 12,
  },
});

const PdfDocument = ({ returnCode, restaurant }: PdfDocumentProps): JSX.Element => {
  const getQRCodeImgSrc = useCallback(() => getQRCodeSrc(returnCode.qrCode), [returnCode]);

  const getTemplate = () => {
    const isQREnhanced = isEnhancedUId(returnCode.uId, UIdType.RETURN);
    // FYI: Relevo Glass has id=2 in the database
    if (restaurant?.metaCategories.some((metaCategory: MetaCategory) => metaCategory.id === '2')) {
      return isQREnhanced ? relevoGlassEnhanced : relevoGlass;
    }
    return isQREnhanced ? relevoClassicEnhanced : relevoClassic;
  };

  return (
    <>
      <Document>
        <Page size="A4">
          <View style={styles.view}>
            <Image src={getTemplate} />
            <Image src={getQRCodeImgSrc} style={styles.QRCode} />
            {returnCode.type === ReturnCodeType.OPEN_HOURS ? (
              <Text style={styles.dateText}>{formatDate(new Date(returnCode.recordCreatedAt))}</Text>
            ) : null}
            {returnCode.type === ReturnCodeType.OPEN_HOURS ? (
              <Svg style={styles.clockIcon}>
                <G>
                  <Path
                    fill={colors.black}
                    d="M6.46368 11.9934C10.0056 11.9934 12.9314 9.27984 12.9314 5.99855C12.9314 2.71353 10.0016 0 6.45878 0C2.92086 0 0 2.71353 0 5.99855C0 9.27984 2.92576 11.9934 6.46368 11.9934ZM6.46368 10.957C3.50302 10.957 1.12139 8.74444 1.12139 5.99855C1.12139 3.24893 3.4981 1.04005 6.45878 1.04005C9.42345 1.04005 11.81 3.24893 11.81 5.99855C11.81 8.74444 9.42835 10.957 6.46368 10.957Z"
                  />
                  <Path
                    fill={colors.black}
                    d="M3.17818 6.67683H6.45875C6.70973 6.67683 6.90375 6.49689 6.90375 6.26492V2.31993C6.90375 2.09169 6.70973 1.91174 6.45875 1.91174C6.21667 1.91174 6.01864 2.09169 6.01864 2.31993V5.85592H3.17818C2.92719 5.85592 2.73718 6.03669 2.73718 6.26492C2.73718 6.49689 2.92719 6.67683 3.17818 6.67683Z"
                  />
                </G>
              </Svg>
            ) : null}
            <Text style={styles.text}>{restaurant?.restaurantName}</Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default PdfDocument;
