import HowItWorksModal from '../../common/components/HowItWorks/HowItWorksModal';
import { useHtmlTranslations } from '../../contexts/LocalizationContext';

export const RetterRelevoHowItWorks = () => {
  const translations = useHtmlTranslations();
  const sections = [
    {
      title: translations('retter_relevo_how_it_works.create_offer_title'),
      content: translations('retter_relevo_how_it_works.create_offer_content'),
    },
    {
      title: translations('retter_relevo_how_it_works.edit_offer_title'),
      content: translations('retter_relevo_how_it_works.edit_offer_content'),
    },
    {
      title: translations('retter_relevo_how_it_works.reuse_title'),
      content: translations('retter_relevo_how_it_works.reuse_content'),
    },
    {
      title: translations('retter_relevo_how_it_works.notify_customers_title'),
      content: translations('retter_relevo_how_it_works.notify_customers_content'),
    },
  ];
  return <HowItWorksModal sections={sections} />;
};
