import { RetterRelevoResponse } from '../model/retter-relevo-response';
import { RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START } from './retter-relevos.consts';
import { parsePickupDateAndPickupTimeFromIntoDate } from './retter-relevos.dates';

export const canEditRetterRelevo = (retterRelevo: RetterRelevoResponse): boolean => {
  const offerStartDate = parsePickupDateAndPickupTimeFromIntoDate(retterRelevo.pickupDate, retterRelevo.pickupTimeFrom);

  const maxEditTime = new Date(offerStartDate);
  maxEditTime.setHours(maxEditTime.getHours() - RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START);

  return maxEditTime.getTime() > new Date().getTime();
};

export const canDeleteRetterRelevo = (retterRelevo: RetterRelevoResponse): boolean => {
  const offerStartDate = parsePickupDateAndPickupTimeFromIntoDate(retterRelevo.pickupDate, retterRelevo.pickupTimeFrom);

  const maxEditTime = new Date(offerStartDate);
  maxEditTime.setHours(maxEditTime.getHours() - RETTER_RELEVOS_MIN_HOURS_BEFORE_OFFER_START);

  return maxEditTime.getTime() > new Date().getTime() || offerStartDate.getTime() < new Date().getTime();
};

export const isRetterRelevoActive = (retterRelevo: RetterRelevoResponse): boolean => {
  const offerStartDate = parsePickupDateAndPickupTimeFromIntoDate(retterRelevo.pickupDate, retterRelevo.pickupTimeFrom);

  // Get the current date and add 2 hours
  const minStartTime = new Date();

  return offerStartDate.getTime() >= minStartTime.getTime();
};
