import React from 'react';
import { Role } from 'contexts/SessionContext';
import ReturnStation from 'modules/RelevoAssignment/components/ReturnStation/ReturnStation';

import Contact from '../modules/Contact';
import ReportDamagedItems from '../modules/DamagedItemsReporting/ReportDamagedItems';
import { BorrowDeposit } from '../modules/Deposit/components/BorrowDeposit/BorrowDeposit';
import { ReturnDeposit } from '../modules/Deposit/components/ReturnDeposit/ReturnDeposit';
import { Deposit } from '../modules/Deposit/Deposit';
import FrequentlyAskedQuestions from '../modules/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import History from '../modules/History';
import Home from '../modules/Home';
import SurveySubmitted from '../modules/Home/SurveySubmitted';
import InfoPoint from '../modules/InfoPoint/InfoPoint';
import Inventory from '../modules/Inventory';
import { InventoryCounts } from '../modules/InventoryCounts/InventoryCounts';
import Locations from '../modules/Locations';
import Profile from '../modules/Profile';
import { QuickContacts } from '../modules/QuickContacts/QuickContacts';
import AssignRelevo from '../modules/RelevoAssignment/AssignRelevo';
import CardUpgrade from '../modules/RelevoAssignment/CardUpgrade';
import ReturnRelevo from '../modules/RelevoAssignment/ReturnRelevo';
import Reports from '../modules/Reports';
import EditRestaurant from '../modules/Restaurants/EditRestaurant';
import { RetterRelevos } from '../modules/RetterRelevos/RetterRelevos';
import { ReturnCodesWrapper } from '../modules/ReturnCodes/ReturnCodesWrapper';
import { Routes } from './routes';

type RouterItem = {
  path: Routes;
  element?: React.ReactNode;
  render?: (props: any) => React.ReactNode;
  roles?: Role[];
};

export const ROUTER_ITEMS: RouterItem[] = [
  { path: Routes.HOME_PATH, element: <Home />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.LOCATIONS_PATH, element: <Locations />, roles: [Role.MANAGER] },
  { path: Routes.RETURN_STATION_PATH, element: <ReturnStation />, roles: [Role.RETURN_STATION] },
  { path: Routes.HISTORY_PATH, element: <History />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.RETURN_RELEVO_PATH, element: <ReturnRelevo />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.ASSIGN_RELEVO_PATH, element: <AssignRelevo />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.INVENTORY_PATH, element: <Inventory />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.RELEVO_CARD_UPGRADE, element: <CardUpgrade />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.REPORTS_PATH, element: <Reports />, roles: [Role.MANAGER] },
  { path: Routes.CONTACT_PATH, element: <Contact />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.PROFILE_PATH, element: <Profile />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.REPORT_DAMAGED_ITEMS, element: <ReportDamagedItems />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.SURVEY_SUBMITTED, element: <SurveySubmitted />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.INFOPOINT, element: <InfoPoint />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  {
    path: Routes.FREQUENTLY_ASKED_QUESTIONS,
    element: <FrequentlyAskedQuestions />,
    roles: [Role.MANAGER, Role.EMPLOYEE],
  },
  { path: Routes.RESTAURANT_PATH, element: <EditRestaurant />, roles: [Role.MANAGER] },
  { path: Routes.DEPOSIT_PATH, element: <Deposit />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.DEPOSIT_BORROW_PATH, element: <BorrowDeposit />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.DEPOSIT_RETURN_PATH, element: <ReturnDeposit />, roles: [Role.MANAGER, Role.EMPLOYEE] },
  { path: Routes.QUICK_CONTACTS_PATH, element: <QuickContacts />, roles: [Role.MANAGER] },
  { path: Routes.INVENTORY_COUNTS_PATH, element: <InventoryCounts />, roles: [Role.MANAGER] },
  { path: Routes.RETURN_CODES_PATH, element: <ReturnCodesWrapper />, roles: [Role.MANAGER] },
  { path: Routes.RETTER_RELEVOS_PATH, element: <RetterRelevos />, roles: [Role.MANAGER] },
];
