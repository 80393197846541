import { Box, Button, Flex, Heading, Image, Spacer, Text, useDisclosure } from '@chakra-ui/react';

import { useTranslations } from '../../contexts/LocalizationContext';
import { useRestaurantsData } from '../../services/restaurants';
import { EUR_CURRENCY_SYMBOL } from '../../utils/money.consts';
import { mapRetterRelevoResponseToFormModel } from './form/retter-relevo-form';
import { RetterRelevosPickupInstructionsModal } from './modals/RetterRelevosPickupInstructionsModal';
import { RetterRelevoResponse } from './model/retter-relevo-response';
import { formatDateForRetterRelevos } from './utils/retter-relevos.dates';
import { canDeleteRetterRelevo, canEditRetterRelevo, isRetterRelevoActive } from './utils/retter-relevos.validators';
import { CreateRetterRelevo } from './CreateRetterRelevo';
import { DeleteRetterRelevo } from './DeleteRetterRelevo';
import { EditRetterRelevo } from './EditRetterRelevo';

type RetterRelevosOfferCardProps = {
  retterRelevo: RetterRelevoResponse;
};

const getContentImagePath = (imageName: string) => require(`../../assets/images/${imageName}`);

const RetterRelevosOfferCard = ({ retterRelevo }: RetterRelevosOfferCardProps) => {
  const { restaurants } = useRestaurantsData();
  const restaurantIdsFromContext = restaurants.map((restaurant) => restaurant.id);
  const translations = useTranslations();
  const {
    isOpen: isPickupInstructionsModalOpen,
    onOpen: onPickupInstructionsModalOpen,
    onClose: onPickupInstructionsModalClose,
  } = useDisclosure();

  const isOfferActive = isRetterRelevoActive(retterRelevo);
  const canEditOffer = canEditRetterRelevo(retterRelevo);
  const canDeleteOffer = canDeleteRetterRelevo(retterRelevo);
  return (
    <>
      <Box
        flex={{
          base: '1 1 100%',
          md: '1 1 calc(25% - 1.5rem)',
        }}
        maxWidth={{
          base: '100%',
          md: '412px',
        }}
        height="auto"
        borderRadius="md"
        overflow="hidden"
        backgroundColor={'grey.30'}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box position="relative">
          <Image
            src={retterRelevo.image?.imageUrl ?? getContentImagePath('retterRelevosBackground.png')}
            width="100%"
            height="200px"
            objectFit="cover"
            opacity={isOfferActive ? 1 : 0.5}
          />

          <Flex
            position="absolute"
            top="0"
            left="0"
            width="100%"
            justify="space-between"
            align="center"
            p="4"
            color="white"
          >
            <CreateRetterRelevo
              retterRelevo={{
                ...mapRetterRelevoResponseToFormModel(retterRelevo),
                id: undefined,
                restaurantIds: retterRelevo.restaurantIds.filter((id) => restaurantIdsFromContext.includes(id)),
              }}
              triggerButton={
                <Button size="sm" variant="orangeSolid">
                  {translations('retter_relevos_reuse')}
                </Button>
              }
            />

            <EditRetterRelevo retterRelevoId={retterRelevo.id} canEditOffer={canEditOffer} />
            <Spacer />
            <DeleteRetterRelevo retterRelevoId={retterRelevo.id} canDeleteOffer={canDeleteOffer} />
          </Flex>
        </Box>

        <Box px="4" py="2" flex="1" opacity={isOfferActive ? 1 : 0.5}>
          <Flex justify="space-between" mb="2">
            <Heading size="md">{retterRelevo.title}</Heading>
            <Text fontWeight="bold">{retterRelevo.price ? EUR_CURRENCY_SYMBOL + ' ' + retterRelevo.price : null}</Text>
          </Flex>
          <Text fontSize="sm" align="left">
            <b>{translations('retter_relevo_form_locations')}: </b>
            {retterRelevo.restaurants.map((restaurant) => restaurant.name).join(', ')}
          </Text>
          {retterRelevo.availableAmount ? (
            <Text fontSize="sm" align="left">
              <b>{translations('retter_relevo_form_amount')}: </b>
              {retterRelevo.availableAmount}
            </Text>
          ) : null}
          <Text fontSize="sm" align="left">
            <b>{translations('retter_relevo_form_pickup_date')}: </b>
            {formatDateForRetterRelevos(translations, retterRelevo.pickupDate)}
          </Text>
          <Text fontSize="sm" align="left">
            <b>{translations('retter_relevo_form_pickup_timeframe')}: </b>
            {retterRelevo.pickupTimeFrom} - {retterRelevo.pickupTimeTo}
          </Text>
        </Box>

        <Flex p="4" justify="space-between" align="center" opacity={isOfferActive ? 1 : 0.5}>
          {retterRelevo.pickupInstructions ? (
            <Button size="sm" variant="white" width="100%" onClick={onPickupInstructionsModalOpen}>
              {translations('retter_relevo_pickup_instructions')}
            </Button>
          ) : null}
        </Flex>
      </Box>
      <RetterRelevosPickupInstructionsModal
        isOpen={isPickupInstructionsModalOpen}
        onClose={onPickupInstructionsModalClose}
        pickupInstructions={retterRelevo.pickupInstructions}
      />
    </>
  );
};

export default RetterRelevosOfferCard;
