import React, { PropsWithChildren, useMemo } from 'react';
import Select, { components, GroupBase, MultiValue, MultiValueProps, OptionProps } from 'react-select';
import { Box, Checkbox } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { cutLongString } from '../../../utils/stringHelper';
import { SelectOption } from '../Select/model/selectOption';
import { selectStyle } from '../Select/select-style';

const INPUT_TRIMMED_VALUE_LIMIT = 23;

const CustomOption = (props: OptionProps<SelectOption>) => {
  const { data, isSelected, innerRef, innerProps } = props;

  return (
    <Box ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
      <Checkbox
        isChecked={isSelected}
        marginRight="8px"
        colorScheme="orange"
        variant="disabledOrange"
        isDisabled={true}
      />
      <components.Option {...props}>{data.label}</components.Option>
    </Box>
  );
};

const MultiValues = ({
  children,
  ...props
}: PropsWithChildren<
  MultiValueProps<{ value: string; label: string }, true, GroupBase<{ value: string; label: string }>>
>) => {
  const translations = useTranslations();
  const { getValue, index, options } = props;
  if (index > 0) return null;

  const { length } = getValue();
  let inputValue;
  if (length === 1) {
    inputValue = cutLongString(getValue()[0].label, INPUT_TRIMMED_VALUE_LIMIT);
  }
  if (options.length === length && length !== 1) {
    inputValue = translations('all_locations', {
      '{{count}}': length.toString(),
    });
  }
  if (length > 1 && options.length !== length) {
    inputValue = translations('selected_locations', {
      '{{count}}': length.toString(),
    });
  }
  return <div>{inputValue}</div>;
};

type RestaurantMultiSelectProps = {
  selectedRestaurantIds: string[];
  userRestaurants: { id: string; restaurantName: string }[];
  onSelectRestaurants: (value: MultiValue<SelectOption>) => void;
  isDisabled?: boolean;
  menuPortalTarget?: HTMLElement;
  placeholder?: string;
};

const RestaurantMultiSelect = ({
  selectedRestaurantIds,
  userRestaurants,
  onSelectRestaurants,
  isDisabled,
  menuPortalTarget,
  placeholder,
}: RestaurantMultiSelectProps) => {
  const translations = useTranslations();
  const restaurantSelectOptions = useMemo(
    () =>
      userRestaurants.map((restaurant) => ({
        value: restaurant.id,
        label: restaurant.restaurantName,
      })),
    [userRestaurants],
  );

  return (
    <Select<SelectOption, true>
      menuPortalTarget={menuPortalTarget}
      value={restaurantSelectOptions.filter((option) => selectedRestaurantIds.includes(option.value))}
      isMulti
      onChange={onSelectRestaurants}
      options={restaurantSelectOptions}
      styles={selectStyle}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ MultiValue: MultiValues, Option: CustomOption }}
      isDisabled={isDisabled}
      menuPlacement="auto"
      placeholder={placeholder ?? translations('restaurants_select_placeholder')}
    />
  );
};

export default RestaurantMultiSelect;
