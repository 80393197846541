import { format } from 'date-fns';
import de from 'date-fns/locale/de';

import config from '../../../config/config';
import { LOCALIZATION_CONFIG } from '../../../config/configConsts';
import { TranslationsArgs } from '../../../contexts/LocalizationContext/hooks';

export const formatDateForRetterRelevos = (
  translations: { (key: string, args?: TranslationsArgs): string },
  date: string | Date,
): string => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  if (new Date(date).toDateString() === today.toDateString()) {
    return translations('retter_relevo_today');
  }

  if (new Date(date).toDateString() === tomorrow.toDateString()) {
    return translations('retter_relevo_tomorrow');
  }
  return format(new Date(date), LOCALIZATION_CONFIG[config.language].dateFormat, {
    locale: de,
  });
};

export const parsePickupDateAndPickupTimeFromIntoDate = (pickupDate: string, pickupTimeFrom: string): Date => {
  // Parse pickupDate and pickupTimeFrom into a valid Date object
  const [hours, minutes] = pickupTimeFrom.split(':').map(Number);
  const offerStartDate = new Date(pickupDate);
  offerStartDate.setHours(hours, minutes, 0, 0);

  return offerStartDate;
};
