import { useContext } from 'react';

import { ReturnCodesContext as ReturnCodesContextModel } from '../model/ReturnCodesContext';
import { ReturnCodesContext } from '../ReturnCodesContext';

export const useReturnCodesContext = (): ReturnCodesContextModel => {
  const ctx = useContext(ReturnCodesContext);
  if (!ctx) {
    throw new Error('No context provided for ReturnCodes');
  }
  return ctx;
};
