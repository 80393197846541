import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';

interface RetterRelevosOfferLimitReachedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RetterRelevosOfferLimitReachedModal = ({ isOpen, onClose }: RetterRelevosOfferLimitReachedModalProps) => {
  const translations = useTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>🛑 {translations('retter_relevo_offer_limit_reached_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={4}>
          <Text mb={2}>{translations('retter_relevo_offer_limit_reached_modal_body')}</Text>
          <Button width={'100%'} mb={2} onClick={onClose}>
            {translations('retter_relevo_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
