import { QueryObserverResult, useMutation, UseMutationResult, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import {
  createReturnCodes,
  deactivateReturnCode,
  getCitiesForReturnCodesFilters,
  getRestaurantsWithReturnCodesCount,
  getReturnCodesForRestaurant,
  ReturnCodesLocationsFilters,
} from '../api/return-code';
import { HttpException } from '../common/models/httpException';
import { Paginated } from '../common/models/paginated';
import { ReturnCodeCreateRequest } from '../modules/ReturnCodes/context/model/ReturnCodeCreate.request';
import { ReturnCodeCreateResponse } from '../modules/ReturnCodes/context/model/ReturnCodeCreate.response';
import { ReturnCodesCity } from '../modules/ReturnCodes/context/model/ReturnCodesCity';
import { ReturnCodesLocation } from '../modules/ReturnCodes/context/model/ReturnCodesLocation';
import { ReturnCodesResult } from '../modules/ReturnCodes/context/model/ReturnCodesResult';

export const CK_RESTAURANT_WITH_RETURN_CODES_COUNT = 'restaurantWithReturnCodesCount';
export const CK_CITIES_FOR_RETURN_CODES_FILTERS = 'citiesForReturnCodesFilters';
export const CK_RETURN_CODES_FOR_RESTAURANT = 'returnCodesForRestaurant';

const getReturnCodesQueryKey = (filters: ReturnCodesLocationsFilters, offset: number, limit: number) => [
  CK_RESTAURANT_WITH_RETURN_CODES_COUNT,
  { offset, limit, ...filters },
];

const getCitiesQueryKey = (filters: ReturnCodesLocationsFilters) => [CK_CITIES_FOR_RETURN_CODES_FILTERS, filters];

export const useRestaurantsWithReturnCodesCount = (
  filters: ReturnCodesLocationsFilters,
  offset: number,
  limit: number,
): QueryObserverResult<Paginated<ReturnCodesLocation>, AxiosError> => {
  return useQuery(
    getReturnCodesQueryKey(filters, offset, limit),
    () => getRestaurantsWithReturnCodesCount(filters, offset, limit),
    { refetchOnWindowFocus: false },
  );
};

export const useCitiesForReturnCodesFilters = (
  filters: ReturnCodesLocationsFilters,
): QueryObserverResult<ReturnCodesCity[], AxiosError> => {
  return useQuery(getCitiesQueryKey(filters), () => getCitiesForReturnCodesFilters(filters), {
    refetchOnWindowFocus: false,
  });
};

export const useReturnCodesForRestaurant = (restaurantId: string): QueryObserverResult<ReturnCodesResult> => {
  return useQuery([CK_RETURN_CODES_FOR_RESTAURANT, restaurantId], () => getReturnCodesForRestaurant(restaurantId), {
    refetchOnWindowFocus: false,
  });
};

export const useReturnCodesCreate = (): UseMutationResult<
  ReturnCodeCreateResponse,
  AxiosError<HttpException>,
  { request: ReturnCodeCreateRequest }
> => {
  return useMutation(({ request }: { request: ReturnCodeCreateRequest }) => createReturnCodes(request));
};

export const useDeactivateReturnCode = (returnCodeId: string) => {
  return useMutation(() => deactivateReturnCode(returnCodeId));
};
