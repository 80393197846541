import React, { JSX } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  ListItem,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  useDisclosure,
} from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';

interface Section {
  title: string | JSX.Element | JSX.Element[];
  content: string | string[] | JSX.Element | JSX.Element[] | (string | JSX.Element | JSX.Element[])[];
}

interface HowItWorksModalProps {
  sections: Section[];
}

const HowItWorksModal = ({ sections }: HowItWorksModalProps) => {
  const translations = useTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={() => onOpen()} width={'100%'} variant="blackSolid">
        {translations('how_it_works_button')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent h="auto" maxH="80%" position="fixed" top="1%" right="1%" transform="none" bg="grey.40">
          <ModalHeader>{translations('how_it_works_modal_header')}</ModalHeader>
          <ModalCloseButton />
          <Flex h="100%" flexDirection="column" px={5} flex="1" overflowY="auto">
            <Accordion>
              {sections.map((section, index) => (
                <AccordionItem key={index} border="none" mb={4} bg={'white'} borderRadius={'8px'}>
                  <Heading as="h2">
                    <AccordionButton _expanded={{ borderBottom: '1px solid', borderColor: 'grey.60' }} px={4}>
                      <Box flex="1" textAlign="left" fontWeight="bold">
                        {section.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel pb={4} px={4}>
                    {Array.isArray(section.content) ? (
                      <OrderedList>
                        {section.content.map((item, itemIndex) => (
                          <ListItem key={itemIndex} _notLast={{ mb: 3 }}>
                            {item}
                          </ListItem>
                        ))}
                      </OrderedList>
                    ) : (
                      section.content
                    )}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
          <ModalFooter>
            <Button colorScheme="orange" width="100%" onClick={onClose}>
              {translations('how_it_works_modal_close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HowItWorksModal;
