import React from 'react';
import { Button, Flex, Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';

import { useTranslations } from '../../../contexts/LocalizationContext';

interface RetterRelevosPickupInstructionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  pickupInstructions?: string | null;
}

export const RetterRelevosPickupInstructionsModal = ({
  isOpen,
  onClose,
  pickupInstructions,
}: RetterRelevosPickupInstructionsModalProps) => {
  const translations = useTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>📦 {translations('retter_relevo_pickup_instruction_modal_title')}</ModalHeader>
        <ModalCloseButton />
        <Flex h="100%" display="column" p={4}>
          <Text mb={2}>{pickupInstructions}</Text>
          <Button width={'100%'} mb={2} onClick={onClose}>
            {translations('retter_relevo_modal_close_button')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
