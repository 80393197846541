import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { RestaurantCreateReturnCodeResponse } from '../../context/model/ReturnCodeCreate.response';
import { getReturnCodePosterFileName } from '../../utils';
import PdfDocument from '../ReturnPosters/PdfDocument';

export const handleDownloadSingleReturnQrPoster = async (data: RestaurantCreateReturnCodeResponse) => {
  const blob = await pdf(
    <PdfDocument
      returnCode={data.returnCode}
      restaurant={{
        restaurantName: data.restaurant.restaurantName || '',
        metaCategories: data.restaurant.metaCategories ?? [],
      }}
    />,
  ).toBlob();
  saveAs(blob, getReturnCodePosterFileName(data.returnCode.name));
};
