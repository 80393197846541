import { generatePath } from 'react-router-dom';

import { Paginated } from '../common/models/paginated';
import { apiPaths } from '../config/apiPaths';
import { RetterRelevoRequest } from '../modules/RetterRelevos/model/retter-relevo-request';
import { RetterRelevoResponse } from '../modules/RetterRelevos/model/retter-relevo-response';
import axios from './axios';
import axiosInstance from './axios';

export const getRetterRelevos = async (
  offset: number,
  limit: number,
  isActive?: boolean,
): Promise<Paginated<RetterRelevoResponse>> => {
  return axios
    .get<Paginated<RetterRelevoResponse>>(apiPaths.RETTER_RELEVOS_PATH, {
      params: { offset, limit, isActive },
    })
    .then((response) => response.data);
};

export const getRetterRelevo = async (retterRelevoId: string): Promise<RetterRelevoResponse> => {
  return axios
    .get<RetterRelevoResponse>(generatePath(apiPaths.RETTER_RELEVOS_RETTER_RELEVO_PATH, { retterRelevoId }))
    .then((response) => response.data);
};

export const createRetterRelevo = async (retterRelevoRequest: RetterRelevoRequest): Promise<RetterRelevoResponse> => {
  return axios
    .post<RetterRelevoResponse>(generatePath(apiPaths.RETTER_RELEVOS_PATH), retterRelevoRequest, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => response.data);
};

export const updateRetterRelevo = async (
  retterRelevoId: string,
  retterRelevoRequest: RetterRelevoRequest,
): Promise<RetterRelevoResponse> => {
  return axios
    .put<RetterRelevoResponse>(
      generatePath(apiPaths.RETTER_RELEVOS_RETTER_RELEVO_PATH, { retterRelevoId }),
      retterRelevoRequest,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
    .then((response) => response.data);
};

export const deleteRetterRelevo = (retterRelevoId: string): Promise<void> => {
  return axiosInstance.delete(generatePath(apiPaths.RETTER_RELEVOS_RETTER_RELEVO_PATH, { retterRelevoId }));
};
