import { RetterRelevoResponse } from '../model/retter-relevo-response';

export interface RetterRelevoFormModel {
  id?: string;
  restaurantIds: string[];
  title: string;
  price?: number | null;
  pickupDate: Date;
  pickupTimeFrom: string;
  pickupTimeTo: string;
  availableAmount?: number | null;
  pickupInstructions?: string | null;
  imageUrl?: string | null;
  imageId?: string | null;
  image?: File;
  temporaryImage?: FileList;
}

export const mapRetterRelevoResponseToFormModel = (response: RetterRelevoResponse): RetterRelevoFormModel => {
  return {
    id: response.id,
    restaurantIds: response.restaurantIds,
    title: response.title,
    price: response.price,
    pickupDate: new Date(response.pickupDate),
    pickupTimeFrom: response.pickupTimeFrom,
    pickupTimeTo: response.pickupTimeTo,
    availableAmount: response.availableAmount,
    pickupInstructions: response.pickupInstructions,
    imageUrl: response.image?.imageUrl,
    imageId: response.image?.id,
  };
};
