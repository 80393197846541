import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  BarcodeCapture,
  BarcodeCaptureFeedback,
  BarcodeCaptureListener,
  barcodeCaptureLoader,
  BarcodeCaptureOverlay,
  BarcodeCaptureSettings,
  Symbology,
} from '@scandit/web-datacapture-barcode';
import {
  Camera,
  CameraSwitchControl,
  configure,
  DataCaptureContext,
  DataCaptureView,
  Feedback,
  FrameSourceState,
  Sound,
  Vibration,
} from '@scandit/web-datacapture-core';

import { SDK, SDKContext } from './model/sdk-context';

export function createSDKFacade(): SDK {
  let context: DataCaptureContext | undefined;
  let view: DataCaptureView | undefined;
  let settings: BarcodeCaptureSettings | undefined;
  let barcodeCapture: BarcodeCapture | undefined;
  let overlay: BarcodeCaptureOverlay | undefined;
  let host: HTMLElement | undefined;
  let cameraSwitchControl: CameraSwitchControl | undefined;
  let camera: Camera | undefined;

  function createHostElementIfNeeded(): HTMLElement {
    if (!host) {
      host = document.createElement('div');
      host.style.visibility = 'hidden';
      host.style.width = '100%';
      host.style.height = '0px';
      document.body.append(host);
    }
    return host;
  }

  return {
    async initialize() {
      await configure({
        libraryLocation: 'https://cdn.jsdelivr.net/npm/@scandit/web-datacapture-barcode@7.0.0/sdc-lib/',
        licenseKey: process.env.REACT_APP_SCANDIT_LICENSE_KEY || '',
        moduleLoaders: [barcodeCaptureLoader()],
      });
      context = await DataCaptureContext.create();
      settings = new BarcodeCaptureSettings();
      settings.enableSymbologies([Symbology.QR]);
      view = await DataCaptureView.forContext(context);
      view.connectToElement(createHostElementIfNeeded());
      cameraSwitchControl = new CameraSwitchControl();
      view.addControl(cameraSwitchControl);
      barcodeCapture = await BarcodeCapture.forContext(context, settings);
      const feedback = BarcodeCaptureFeedback.default;
      feedback.success = new Feedback(null, null);
      await barcodeCapture.setFeedback(feedback);
      await barcodeCapture.setEnabled(false);
      overlay = await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
      await view.addOverlay(overlay);

      camera = Camera.default;
      await camera.applySettings(BarcodeCapture.recommendedCameraSettings);
      await context.setFrameSource(camera);
    },
    async cleanup() {
      await context?.frameSource?.switchToDesiredState(FrameSourceState.Off);
      await context?.dispose();
      await context?.removeAllModes();
      if (overlay) {
        await overlay.setViewfinder(null);
        await view?.removeOverlay(overlay);
      }
      if (cameraSwitchControl) {
        view?.removeControl(cameraSwitchControl);
        cameraSwitchControl = undefined;
      }
      view?.detachFromElement();
      barcodeCapture = undefined;
      context = undefined;
      view = undefined;
      settings = undefined;
      camera = undefined;
      host?.remove();
      host = undefined;
    },
    connectToElement(element: HTMLElement) {
      host = createHostElementIfNeeded();
      host.style.visibility = 'visible';
      host.style.width = '100%';
      host.style.height = '100%';
      element.append(host);
    },
    detachFromElement() {
      if (host) {
        host.style.visibility = 'hidden';
        host.style.height = '0px';
        host.style.width = '0px';
        document.body.append(host);
      }
    },
    async enableCamera(enabled: boolean) {
      if (context?.frameSource) {
        await context.frameSource.switchToDesiredState(enabled ? FrameSourceState.On : FrameSourceState.Off);
      }
    },
    async enableScanning(enabled: boolean) {
      await barcodeCapture?.setEnabled(enabled);
    },
    async enableFeedback(enabled: boolean) {
      if (enabled) {
        const feedback = BarcodeCaptureFeedback.default;
        feedback.success = new Feedback(Vibration.defaultVibration, Sound.defaultSound);
        await barcodeCapture?.setFeedback(feedback);
      } else {
        const feedback = BarcodeCaptureFeedback.default;
        feedback.success = new Feedback(null, null);
        await barcodeCapture?.setFeedback(feedback);
      }
    },
    getFeedback() {
      return barcodeCapture?.feedback;
    },
    addBarcodeCaptureListener(listener: BarcodeCaptureListener) {
      barcodeCapture?.addListener(listener);
    },
    removeBarcodeCaptureListener(listener: BarcodeCaptureListener) {
      barcodeCapture?.removeListener(listener);
    },
  };
}

export interface SDKProviderProps {
  children: ReactNode;
}

export default function ScanditSDKContextProvider({ children }: SDKProviderProps) {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const sdk = useMemo(() => createSDKFacade(), []);

  const providerValue = useMemo(() => ({ loading, loaded, sdk }), [loading, loaded, sdk]);

  useEffect(() => {
    async function start(): Promise<void> {
      setLoading(true);
      await sdk.initialize();
      setLoading(false);
      setLoaded(true);
      // FYI: enable the camera on mount to speed up the access
      // await sdk.enableCamera(true);
    }
    void start();
    return () => {
      void sdk.cleanup();
    };
  }, [sdk]);

  return <SDKContext.Provider value={providerValue}>{children}</SDKContext.Provider>;
}
